import React from "react";

import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";

import Particles from 'react-particles-js';
import AdvisorComponent from "./AdvisorComponent";
import TieredSystemComponent from './TieredSystemComponent'
import { useTranslation } from "react-i18next";
// import BannerComponent from "../../component/shared/BannerComponent";


const HomePage = (props) => {
  const { t } = useTranslation();

  return (
    <>
      {/* <BannerComponent /> */}
      <div className="pp-homepage">
        <div className="pp-home-banner">
          <div className="container">
            <h1 className="text-center text-uppercase" data-aos="fade-up" data-aos-delay="100">{t('KCCPAD IS THE FIRST DECENTRALIZED & DEFLATIONARY IDO PLATFORM FOR THE KUCOIN COMMUNITY CHAIN')}</h1>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <p data-aos="fade-up" data-aos-delay="200">
                  {t('KCCPAD is a deflationary launch pad, there is a 1% fee for selling, a 25% fee for early unstaking and projects will need to commit to buying and burning tokens to launch their IDOs.')}
                </p>
              </div>
            </div>
            <div className="mt-4">
              <Link data-aos="fade-up" data-aos-delay="300" className="btn btn-primary btn-lg me-3" to={ROUTES.PROJECTS}>{t('View all Projects')}</Link>
              <a target="_blank" className="d-inline-flex align-items-center justify-content-center btn btn-primary btn-pancake btn-lg btn-buy-on me-3" href="https://pancakeswap.finance/swap?outputCurrency=0x11582Ef4642B1e7F0a023804B497656E2663bC9B">
                <b style={{ whiteSpace: 'nowrap' }} className="d-flex align-items-center justify-content-center">{t('Buy on')}&nbsp;<img src="/images/pancake-swap.png" height="20" />&nbsp;Pancake Swap</b>
              </a>
              <a target="_blank" className="d-inline-flex align-items-center justify-content-center btn btn-primary btn-lg btn-buy-on me-3" href="https://kccswap.io/#/swap?outputCurrency=0x1f0af881b89b8d9573b691cb65933f6d897aba99">
                <b style={{ whiteSpace: 'nowrap' }} className="d-flex align-items-center justify-content-center">{t('Buy on')}&nbsp;<img src="/images/Z219T8c7_400x400.png" height="20" />&nbsp;KCC SWAP</b>
              </a>
              <a data-aos="fade-up" data-aos-delay="400" className="btn btn-primary btn-lg me-3" href="https://docs.google.com/forms/d/e/1FAIpQLSedpCSac4MxuC41RKdrbi--3ircOw9SKBt6U_1AWK3JAJih0w/viewform" target="_blank">{t('Apply for IDO')}</a>
            </div>
            <div className="mt-5 text-center">
              {/* <a className="btn btn-outline-primary btn-sm" href="https://t.me/KCCPad_Official" target="_blank">
                <i className="fas fa-paper-plane me-1"></i>
                <span>{t('Join us on Telegram')}</span>
              </a> */}
              <a className="btn btn-outline-primary btn-sm mx-3" href="https://medium.com/@kccpad" target="_blank">
                <i className="fab fa-medium-m me-1"></i>
                <span>{t('Follow our Medium')}</span>
              </a>
              <a className="btn btn-outline-primary btn-sm" href="https://twitter.com/KCCPad_Official" target="_blank">
                <i className="fab fa-twitter me-1"></i>
                <span>{t('Follow our Twitter')}</span>
              </a>
            </div>
          </div>
        </div>

        <div className="pp-home-about pp-section pt-5 pb-0" id="about">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-lg-12 px-lg-0">
                <div className="section-head pb-0">
                  <h2 className="title title-xl title-xl-40" data-aos="fade-up" data-aos-delay="100" title="BRIDGE">KCCPAD Bridge</h2>
                  <p style={{ fontSize: '24px', opacity: 1 }} className="pt-4 text-white">The KCCPad Bridge  is the first bridge from BSC to KCC.</p>
                  <p>The bridge will help to bring millions across from BSC to KCC to drive more volume and users into projects on the KCC network.</p>
                  <div className="pt-3">
                    <a href="http://bridge.kccpad.io" target="_blank" className="btn btn-outline-primary">
                      <span>Go to KCCPAD Bridge</span>
                      <i className="fas fa-long-arrow-alt-right ms-2"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 
        <div className="pp-home-about pp-section pt-5 pb-0" id="about">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-lg-12 px-lg-0">
                <div className="section-head">
                  <h2 className="title title-xl title-xl-40" data-aos="fade-up" data-aos-delay="100" title="TRUST">KCCPad is also the first launch pad fully incubated by BlueZilla Labs.</h2>
                  <p style={{ fontSize: '24px' }} className="pt-4">BlueZilla is the team behind the super successful BSCPad, TronPad and soon to launch ETHPad.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pp-home-partners">
          <div className="container">
            <div className="pp-home-partners-content d-flex align-items-center justify-content-center">
              <a className="me-4 mb-2 mb-lg-0" target="_blank" href="https://bluezilla.vc/" style={{ color: '#2a5ada' }}>
                <img height="48" src="/images/bluzilla_logo.png" alt="partner" />
              </a>
              <a target="_blank" href="https://bscpad.com/">
                <img height="48" src="images/bscpad-l.png" alt="partner" />
              </a>
              <a className="me-md-2" target="_blank" href="https://tronpad.network/">
                <img height="48" src="images/tronpad-l-1.png" alt="partner" />
              </a>
              <a target="_blank" href="https://ethpad.network/">
                <img height="48" src="images/ethpad-white.png" alt="partner" />
              </a>
            </div>
            <div className="text-center pt-5">
              <p style={{ fontSize: '24px' }}>Backed by the full force of the BlueZIlla marketing department.</p>
              <p style={{ fontSize: '24px' }}>Since the massively successful launch of BSCPad, we have demonstrated again and again that our team knows how to ‘move the needle’ with crypto marketing. All of this marketing power is fuelled by the strongest KOL line up in the industry which is growing by the day.</p>
            </div>
          </div>
        </div> */}

        <div className="pp-home-about pp-section" id="about">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-lg-6">
                <div className="section-head">
                  <h2 className="title title-xl" data-aos="fade-up" data-aos-delay="100" title={t('What and Why')}>{t('ABOUT US')}</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="pp-home-about-content pp-section-body mt-4">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6" data-aos="fade-up" data-aos-delay="200">
                  <h6><b>{t('WHAT IS KCCPad?')}</b></h6>
                  <h2>{t('KCCPad is the first decentralized & deflationary IDO platform for the Kucoin Community Chain.')}</h2>
                  <h6 className="mt-4"><b>{t('DEFLATIONARY')}</b></h6>
                  <p className="lead-s2">{t('KCCPAD is a deflationary launch pad, there is a 1% fee for selling, a 25% fee for early unstaking and projects will need to commit to buying and burning tokens to launch their IDOs.')}</p>
                  <h6 className="mt-4"><b>{t('WHY CHOOSE US?')}</b></h6>
                  <p>{t('KCCPad has found a solution to incentivize and reward all token holders in a way that is inclusive and with a low barrier to entry.')}</p>
                  <p dangerouslySetInnerHTML={{ __html: t('The fundamental flaws of existing launchpads is that acquiring enough tokens to participate in the ecosystem is prohibitive, and even if you do hold the tokens, you are not guaranteed an allocation spot. They are based on a first come first serve basis where automated bots can fill the whitelist spots in a matter of seconds. KCCPad is creating fair decentralized launches.') }}></p>
                  <p dangerouslySetInnerHTML={{ __html: t('The hallmark of the KCCPad is a two-round system that makes every tier level guaranteed an allocation. There is no luck, and no lotteries, and no bots; only fair distributed rewards for all participants') }}></p>
                </div>
                <div className="col-md-6">
                  <div className="nk-block-img nk-block-ca" data-aos="fade-up" data-aos-delay="200" style={{ position: 'relative' }}>
                    <div className="nk-circle-animation nk-df-center fast"></div>
                    <img className="shadow rounded w-100 dark-show" src="images/sc-medium-a.png" alt="" />
                    <img className="shadow rounded w-100 light-show" src="images/sc-medium-a.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <TieredSystemComponent />

        <AdvisorComponent />

        <div className="pp-home-contact pp-section py-5" id="contact">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-lg-6">
                <div className="section-head">
                  <h2 className="title title-xl" data-aos="fade-up" data-aos-delay="100" title={t('CONTACT US')}>{t('CONTACT')}</h2>
                  {/* <p data-aos="fade-up" data-aos-delay="200">Any question? Reach out to us and we’ll get back to you shortly.</p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="pp-home-contact-content pp-section-body mt-5">
            <div className="container">
              <div className="row justify-content-center align-items-center gutter-vr-50px">

                <div className="col-lg-5 text-center order-lg-first">
                  <div className="nk-block-contact nk-block-contact-s1" data-aos="fade-up" data-aos-delay="100">
                    <ul className="contact-list ps-1">
                      <li data-aos="fade-up" data-aos-delay="300">
                        <em className="contact-icon fas fa-headset"></em>
                        <div className="contact-text">
                          <a href="https://bluezilla.jitbit.com/helpdesk/" target="_blank"><span>Contact Support</span></a>
                        </div>
                      </li>
                      {/* <li data-aos="fade-up" data-aos-delay="400">
                        <em className="contact-icon fas fa-paper-plane"></em>
                        <div className="contact-text">
                          <a href="https://t.me/KCCPad_Official" target="_blank"> <span>{t('Join us on Telegram')}</span></a>
                        </div>
                      </li> */}
                      <li data-aos="fade-up" data-aos-delay="400">
                        <em className="contact-icon fab fa-twitter"></em>
                        <div className="contact-text">
                          <a href="https://twitter.com/KCCPad_Official" target="_blank"> <span>{t('Follow our Twitter')}</span></a>
                        </div>
                      </li>
                      <li data-aos="fade-up" data-aos-delay="500">
                        <em className="contact-icon fas fa-globe"></em>
                        <div className="contact-text">
                          <a href="#"><span>www.kccpad.io</span></a>
                        </div>
                      </li>
                    </ul>
                    <div className="nk-circle-animation nk-df-center white small"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      {/* <div className="modal fade" id="team-popup-1" tabIndex="-1" aria-labelledby="team-popup-1Label" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-lg modal-dialog-centered pp-modal-team">
          <div className="modal-content">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body">
              <div className="row mb-3">
                <div className="col-md-6 text-center">
                  <div className="mb-3 pe-md-3">
                    <img src="images/JASPER_BYUN.jpeg" alt="team" className="no-bdrs w-100 borras" />
                  </div>
                  <p className="mb-1"><img src="images/blocksync-logo.png" alt="team" className="no-bdrs" height="40" /></p>
                  <p><a target="_blank" href="https://blocksync.com">https://blocksync.com</a></p>
                </div>
                <div className="col-md-6">
                  <h3>JASPER BYUN</h3>
                  <p style={{ opacity: .6 }}>Founder of Blocksync Ventures</p>
                  <p>
                  </p>
                  <hr />
                  <p>Jasper is the founder of Blocksync Ventures and a managing partner at Brilliance Ventures. He has been working with start-ups for over 5 years and has coordinated investments exceeding $50M into world-class distributed technologies projects.</p>
                  <p>He has also guided advisory portfolios to establish key strategic initiatives by securing partnerships, ensuring proper marketing direction, and encompassing overall day-to-day operations.</p>

                </div>
              </div>
              <p>We truly believe KCCPAD investors will benefit from this partnership as we can lean on Jasper’s extensive Fintech background and expertise.</p>
              <p className="mb-0">Jasper is the first of many partners to be revealed in the upcoming days so keep an eye on the KCCPAD telegram channel for future updates!</p>
            </div>
          </div>
        </div>
      </div> */}

      <div className="modal fade" id="team-popup-2" tabIndex="-1" aria-labelledby="team-popup-2Label" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-lg modal-dialog-centered pp-modal-team">
          <div className="modal-content">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body">
              <div className="row mb-3">
                <div className="col-md-6 text-center">
                  <div className="mb-3 pe-md-3">
                    <img src="images/LESTER_LIM.jpeg" alt="team" className="no-bdrs w-100 borras" />
                  </div>
                  <p className="mb-1"><img src="images/x21.png" alt="team" className="no-bdrs" height="40" /></p>
                  <p><a target="_blank" href="https://x21digital.com">https://x21digital.com</a></p>
                </div>
                <div className="col-md-6">
                  <h3>LESTER LIM</h3>
                  <p style={{ opacity: .6 }}>{t('Founder at X21 Digital')}</p>
                  <p>
                    {/* <a className="me-3" target="_blank" href="https://sg.linkedin.com/in/lesterlim15"><em className="fab fa-linkedin-in"></em></a>
                    <a className="me-3" target="_blank" href="https://t.me/Lesterlim15"><em className="fab fa-telegram"></em></a>
                    <a className="me-3" target="_blank" href="https://twitter.com/lesterlim15?lang=en"><em className="fab fa-twitter"></em></a> */}
                  </p>
                  <hr />
                  <p>{t('Lester is the founder of X21 Digital. He incubates and supports promising projects via his marketing experience & vast connections within the blockchain ecosystem. His latest incubation projects include Blank Wallet, DAOventures and Vortex Defi.')}</p>
                  <p>{t('He focuses on strategic directions, token metrics, and connecting the dots as a Strategic Advisor to other projects by bringing in the RIGHT connections and resources to shortcut their success.')}</p>

                </div>
              </div>
              <p>{t('Also Strategic Advisor to PolkaFoundry, Oddz, Project Inverse, MahaDao & Finxflo.')}</p>
              <p className="mb-0">{t('The KCCPAD team and Lester both share a common love of Blockchain Technology – and are excited to leverage his vast connections to bring mass exposure to KCCPAD. We are excited to be a part of the exclusive X21 Digital portfolio and join the other prestigious projects who have benefited from Lester’s guidance.')}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="team-popup-3" tabIndex="-1" aria-labelledby="team-popup-3Label" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-lg modal-dialog-centered pp-modal-team">
          <div className="modal-content">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body">
              <div className="row mb-3">
                <div className="col-md-6 text-center">
                  <div className="mb-3 pe-md-3">
                    <img src="images/Ian_Friend.jpeg" alt="team" className="no-bdrs w-100 borras" />
                  </div>
                  <p className="mb-1"><img src="images/partner-1.png" alt="team" className="no-bdrs" height="40" /></p>
                  <p><a target="_blank" href="https://ferrum.network/">https://ferrum.network/</a></p>
                </div>
                <div className="col-md-6">
                  <h3>IAN FRIEND</h3>
                  <p style={{ opacity: .6 }}>{t('Co-Founder and COO at Ferrum Network')}</p>
                  <p>
                    {/* <a className="me-3" target="_blank" href="https://www.linkedin.com/in/ian-friend-bb949657"><em className="fab fa-linkedin-in"></em></a> */}
                  </p>
                  <hr />
                  <p>{t('Ian Friend is the Co-Founder and COO at Ferrum Network.')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="team-popup-4" tabIndex="-1" aria-labelledby="team-popup-3Label" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-lg modal-dialog-centered pp-modal-team">
          <div className="modal-content">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body">
              <div className="row mb-3">
                <div className="col-md-6 text-center">
                  <div className="mb-3 pe-md-3">
                    <img src="images/Danish_Chaudhry.jpg" alt="team" className="no-bdrs w-100 borras" />
                  </div>
                  <p className="mb-1"><img src="images/exchange.png" alt="team" className="no-bdrs" style={{ maxWidth: '250px', maxHeight: '40px' }} /></p>
                  <p><a target="_blank" href="https://exchange.bitcoin.com/">https://exchange.bitcoin.com/</a></p>
                </div>
                <div className="col-md-6">
                  <h3>DANISH CHAUDHRY</h3>
                  <p style={{ opacity: .6 }}>{t('CEO at Bitcoin.com Exchange – Entrepreneur, Startup Advisor, Mentor and Investor')}</p>
                  <p>
                    {/* <a className="me-3" target="_blank" href="https://www.linkedin.com/in/djchaudhry/"><em className="fab fa-linkedin-in"></em></a>
                    <a className="me-3" target="_blank" href="https://twitter.com/djchaudhry_"><em className="fab fa-twitter"></em></a> */}
                  </p>
                  <hr />
                  <p>{t('Danish Chaudhry is the CEO of Bitcoin.com Exchange, an up and coming exchange that focuses on bringing the most promising projects to market. He is an active investor and advisor, having backed some of the most prominent projects out there from an early stage such as matic (now polygon), marlin, cere, moonbeam, sifchain, flow, graph and the list goes on.')}</p>
                  <p>{t("Danish has been in the blockchain space since the early days. He has a background in finance where he was a PM at Blackrock for a number of years, having founded and successfully exited two startups, he's built fintech and insurtech incubators at his previous VC that have produced startups valued at over $2bn.")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="team-popup-5" tabIndex="-1" aria-labelledby="team-popup-3Label" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-lg modal-dialog-centered pp-modal-team">
          <div className="modal-content">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body">
              <div className="row mb-3">
                <div className="col-md-6 text-center">
                  <div className="mb-3 pe-md-3">
                    <img src="images/exntc.png" alt="team" className="no-bdrs w-100 borras" />
                  </div>
                  <p className="mb-1"><img src="images/exnetwork_capital.jpg" alt="team" className="no-bdrs" height="40" /></p>
                  <p><a target="_blank" href="https://exnetworkcapital.com">https://exnetworkcapital.com</a></p>
                </div>
                <div className="col-md-6">
                  <h3>EXNETWORK CAPITALY</h3>
                  <p style={{ opacity: .6 }}>{t("Exnetwork Capital is an investment firm focused on funding and incubating blockchain projects. It is composed of a DAO of different investors all helping out to ensure the portfolio companies' success.")}</p>
                  <p>
                    {/* <a className="me-3" target="_blank" href="https://www.linkedin.com/in/djchaudhry/"><em className="fab fa-linkedin-in"></em></a> */}
                    {/* <a className="me-3" target="_blank" href="https://twitter.com/exnetworkcap"><em className="fab fa-twitter"></em></a> */}
                  </p>
                  <hr />
                  <p>{t("Exnetwork Capital's incubator program will help mentor KCCPAD and provide support from their network of entrepreneurs who will help take the BSC Launch Pad to new levels. Exnetwork primarily focuses on the decentralized market and can assist with many areas such as token design, fundraising, and marketing. New projects entering the launchpad will be able to glean much knowledge from the Exnetwork Capital team.")}</p>
                  <p>{t('We are aligned in supporting the creation and launching of new projects. We aim to help these new BSC projects to succeed and garner the same attention, hype and love as their erc counterparts.')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="team-popup-6" tabIndex="-1" aria-labelledby="team-popup-6Label" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-lg modal-dialog-centered pp-modal-team">
          <div className="modal-content">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body">
              <div className="row mb-3">
                <div className="col-md-6 text-center">
                  <div className="mb-3 pe-md-3">
                    <img src="images/TIM_FROST.jpg" alt="team" className="no-bdrs w-100 borras" />
                  </div>
                  <p className="mb-1"><img src="images/yield.png" alt="team" className="no-bdrs" height="40" /></p>
                  <p><a target="_blank" href="https://www.yield.app">https://www.yield.app</a></p>
                </div>
                <div className="col-md-6">
                  <h3>TIM FROST</h3>
                  <p style={{ opacity: .6 }}>{t("Tim Frost is CEO and co-founder of YIELD App which is one of the fastest growing fintech companies in the crypto space. He has extensive experience in FinTech, marketing, business development, and operations. YIELD App is the third digital finance platform Tim has brought to the market. He was a founding member of the Wirex team and supported operations, business development, and marketing for the first 18 months. Tim also helped bring EQI Bank to the global market as a digital challenger to conventional banking, with an average client AUM of $250,000. Tim's experience in blockchain technology includes early involvement with numerous successful projects including QTUM, NEO, Paxful, Polymath, Selfkey and Everex. Tim has been building crypto and fintech companies since 2014.")}</p>
                  <p>
                    {/* <a className="me-3" target="_blank" href="https://hk.linkedin.com/in/timfrost55"><em className="fab fa-linkedin-in"></em></a>
                    <a className="me-3" target="_blank" href="https://twitter.com/TimYIELD"><em className="fab fa-twitter"></em></a> */}
                  </p>
                  <hr />
                  <p>{t('Adding Tim Frost as a strategic partner and advisor reinforces our commitment to aggressive growth for KCCPAD. Tim will help lead our efforts with his vast experience in FinTech, marketing, business development, and operations. We will be utilizing his expertise in these areas to help lead to even higher customer adoption and expansion into new industry verticals with KCCPAD.')}</p>

                  {/* <p><b>The partnership between YIELD &amp; KCCPAD will also include a very lucrative bonus to KCCPAD holders that you will not want to miss out on.</b></p> */}
                </div>
              </div>
              {/* <p>Adding Tim Frost as a strategic partner and advisor reinforces our commitment to aggressive growth for KCCPAD. Tim will help lead our efforts with his vast experience in FinTech, marketing, business development, and operations. We will be utilizing his expertise in these areas to help lead to even higher customer adoption and expansion into new industry verticals with KCCPAD.</p> */}
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="team-popup-8" tabIndex="-1" aria-labelledby="team-popup-8Label" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-lg modal-dialog-centered pp-modal-team">
          <div className="modal-content">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body">
              <div className="row mb-3">
                <div className="col-md-6 text-center">
                  <div className="mb-3 pe-md-3">
                    <img src="images/innovion.png" alt="team" className="no-bdrs w-100 borras" />
                  </div>
                  <p className="mb-1"><img src="images/innovion.svg" alt="team" className="no-bdrs" height="40" /></p>
                  <p><a target="_blank" href="https://innovion.co">https://innovion.co</a></p>
                </div>
                <div className="col-md-6">
                  <h3>INNOVION</h3>
                  <p style={{ opacity: .6 }}>{t('Innovion has built a prestigious reputation with a unique approach to guerilla marketing, collaborating with over 200 blockchain projects.')}</p>
                  <p>
                    <a className="me-3" target="_blank" href="https://www.linkedin.com/company/innovionofficial/"><em className="fab fa-linkedin-in"></em></a>
                  </p>
                  <hr />
                  <p>{t('Innovion will strengthen our Public reach and further grow our client base.')}</p>
                  {/* <p><b>The partnership between YIELD &amp; KCCPAD will also include a very lucrative bonus to KCCPAD holders that you will not want to miss out on.</b></p> */}
                </div>
              </div>
              {/* <p>Adding Tim Frost as a strategic partner and advisor reinforces our commitment to aggressive growth for KCCPAD. Tim will help lead our efforts with his vast experience in FinTech, marketing, business development, and operations. We will be utilizing his expertise in these areas to help lead to even higher customer adoption and expansion into new industry verticals with KCCPAD.</p> */}
            </div>
          </div>
        </div>
      </div>


      <Particles params={{
        "particles": {
          "number": {
            "value": 80,
            "density": {
              "enable": true,
              "value_area": 2000
            }
          },
          "color": {
            "value": "#ffffff"
          },
          "shape": {
            "type": "circle",
            "stroke": {
              "width": 0,
              "color": "#000000"
            },
            "polygon": {
              "nb_sides": 5
            }
          },
          "opacity": {
            "value": 0.5,
            "random": false,
            "anim": {
              "enable": false,
              "speed": 1,
              "opacity_min": 0.1,
              "sync": false
            }
          },
          "size": {
            "value": 3,
            "random": true,
            "anim": {
              "enable": false,
              "speed": 40,
              "size_min": 0.1,
              "sync": false
            }
          },
          "line_linked": {
            "enable": true,
            "distance": 150,
            "color": "#ffffff",
            "opacity": 0.4,
            "width": 1
          },
          "move": {
            "enable": true,
            "speed": 6,
            "direction": "none",
            "random": false,
            "straight": false,
            "out_mode": "out",
            "bounce": false,
            "attract": {
              "enable": false,
              "rotateX": 600,
              "rotateY": 1200
            }
          }
        },
        "interactivity": {
          "detect_on": "canvas",
          "events": {
            "onhover": {
              "enable": true,
              "mode": "repulse"
            },
            "onclick": {
              "enable": true,
              "mode": "push"
            },
            "resize": true
          },
          "modes": {
            "grab": {
              "distance": 400,
              "line_linked": {
                "opacity": 1
              }
            },
            "bubble": {
              "distance": 400,
              "size": 40,
              "duration": 2,
              "opacity": 8,
              "speed": 3
            },
            "repulse": {
              "distance": 200,
              "duration": 0.4
            },
            "push": {
              "particles_nb": 4
            },
            "remove": {
              "particles_nb": 2
            }
          }
        },
        "retina_detect": true
      }} />
    </>
  );
};

export default HomePage;
