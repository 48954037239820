import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";
const TermsOfUsePage = (props) => {
  return (
    <>
      <div className="container py-5">
        <div className="d-flex align-items-start tab-term">
          <div className="nav nav-pills-policy flex-column nav-pills me-4" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <Link to={ROUTES.TERMS_OF_USE} className="nav-link text-start active" id="v-pills-terms-tab">Terms of Use </Link>
            <Link to={ROUTES.PRIVACY_POLICY} className="nav-link text-start" id="v-pills-privacy-policy-tab">Privacy Policy</Link>
            <Link to={ROUTES.COOKIE_POLICY} className="nav-link text-start" id="v-pills-cookies-policy-tab">Cookies Policy</Link>
            <Link to={ROUTES.STAKING_POLICY} className="nav-link text-start" id="v-pills-staking-policy-tab">Staking Policy</Link>
            <Link to={ROUTES.CLICKWRAP_ACCEPTANCE} className="nav-link text-start" id="v-pills-clickwrap-acceptance-tab">Clickwrap Acceptance</Link>
          </div>
          <div className="tab-content" id="v-pills-policy-tabContent">
            <div className="tab-pane fade show active" id="v-pills-cookies-policy" role="tabpanel" aria-labelledby="v-pills-cookies-policy-tab">
              <div>
                <div>
                </div>
                <p className="mb-2" style={{ textAlign: 'center' }}><b>KCC PAD LIMITED</b></p>
                <p className="mb-2" style={{ textAlign: 'center' }}><b>Terms and Conditions of <a href="https://kccpad.io/">https://kccpad.io/</a></b></p>
                <p style={{ textAlign: 'center' }}><small>Last revised Date: 21<sup>st</sup> September 2021</small></p>
                <h3>1. Introduction</h3>
                <p>These Terms and Conditions (herein referred as “<strong style={{ fontSize: '14px' }}>Terms</strong><span style={{ fontSize: '14px' }}>”) govern the use and the conditions of https://kccpad.io/ (herein referred as “</span><strong style={{ fontSize: '14px' }}>Website</strong><span style={{ fontSize: '14px' }}>”), and the Services via KCCPAD Platform provided by KCC Pad Limited (herein referred as “</span><strong style={{ fontSize: '14px' }}>KCCP</strong><span style={{ fontSize: '14px' }}>” or “</span><strong style={{ fontSize: '14px' }}>we</strong><span style={{ fontSize: '14px' }}>” or “</span><strong style={{ fontSize: '14px' }}>us</strong><span style={{ fontSize: '14px' }}>” or “</span><strong style={{ fontSize: '14px' }}>our</strong><span style={{ fontSize: '14px' }}>”, which shall have the same meaning in these Terms), a company incorporated and registered under the laws of the Republic of Seychelles. These Terms constitute a binding and enforceable legal contract between us and our Affiliates and subsidiaries worldwide and you, an end user of the Services (herein referred as “</span><strong style={{ fontSize: '14px' }}>you</strong><span style={{ fontSize: '14px' }}>” or “</span><strong style={{ fontSize: '14px' }}>user</strong><span style={{ fontSize: '14px' }}>”) in relation to the Services. You and KCCP are referred to separately as “</span><strong style={{ fontSize: '14px' }}>Party</strong><span style={{ fontSize: '14px' }}>” and collectively as “</span><strong style={{ fontSize: '14px' }}>Parties</strong><span style={{ fontSize: '14px' }}>”</span></p>
                <p>By accessing, registering, unlocking, using, or clicking on the Services, and information made available by us via the Website, you hereby accept and agree to all the Terms set forth herein.</p>
                <p>You are strongly advised to carefully read these Terms as well as the provisions detailed in our Privacy Policy prior to using the Website and our Services. By using the Website and the Services in any capacity, you agree that: (i) you have read and familiarized yourself with these Terms; (ii) you understand these Terms; and (iii) you agree to be bound by these Terms when using the Website. If you do not agree to these Terms, please do not access or use the Website and the Services.</p>
                <p>We reserve the right to modify or amend these Terms, the Website, or any content on either one of the platforms from time to time, including for security, legal, or regulatory reasons, as well as to reflect updates or changes to the Services or functionality of the Website. You are advised to check these Terms periodically to ensure that you are cognizant of the current versions and comply with them. Users of the Website and the Services are bound by these changes which will take immediate effect after the revised versions of these Terms have been published on the Website or mobile application. Through your continued use of or interaction with the Website, the Services, tools, and information made available on these platforms, you hereby agree to be bound by the provisions highlighted in the subsequent versions.</p>
                <p>We will provide a notification on the Website specifying that changes have been made to these Terms whenever they occur. By accepting the notification, you accept that we have provided you with sufficient notice of any changes. You should seek professional advice regarding any possible legal requirements you must comply with in relation to use of the Website, the Service or the relevant tools provided by us or the Partners.</p>
                <p style={{ paddingLeft: '30px' }}>&nbsp;</p>
                <h3>2. Definitions</h3>
                <p style={{ paddingLeft: '30px' }}><strong>“ADAPAD”<span>&nbsp;</span></strong>refers to the platform for Cardano Smart Chain Network, which is a platform for retail investment into tokens offered via IDO. Please find further details at<span>&nbsp;</span><a href="https://adapad.io">https://adapad.io</a></p>
                <p style={{ paddingLeft: '30px' }}><strong>"A</strong><strong>ffiliate(s)</strong><strong>"<span>&nbsp;</span></strong>refers to in relation in any party, any other company which, directly or indirectly, (i) is controlled by that party, (ii) controls that party, or (iii) is under common control with that party, and in respect of us, shall also include any fund, limited partnership or other collective investment vehicle or other person which is managed or advised by us.</p>
                <p style={{ paddingLeft: '30px' }}><strong>"AML"<span>&nbsp;</span></strong>refers to anti-money laundering.</p>
                <p style={{ paddingLeft: '30px' }}><strong>"Applicable Laws"<span>&nbsp;</span></strong>refers to acts, statutes, regulations, ordinances, treaties, guidelines, and policies issued by governmental organizations or regulatory bodies, including, but not limited to, the governing law stipulated under Laws of the Republic of Seychelles.</p>
                <p style={{ paddingLeft: '30px' }}><strong>“BSCPAD”<span>&nbsp;</span></strong>refers to the first platform for Binance Smart Chain Network, which is a platform for retail investment into tokens offered via IDO. Please find further details at<span>&nbsp;</span><a href="https://bscpad.com/">https://bscpad.com/</a>.</p>
                <p style={{ paddingLeft: '30px' }}><strong>“B</strong><strong>ridge Service</strong><strong>”</strong><span>&nbsp;</span>refers to the service provided by us and our Affiliates in Clause 6.</p>
                <p style={{ paddingLeft: '30px' }}><strong>"CFT"<span>&nbsp;</span></strong>refers to combating the financing of terrorism.</p>
                <p style={{ paddingLeft: '30px' }}><strong>"Confidential Information"<span>&nbsp;</span></strong>refers to any non-public, proprietary information or documents of or related to the user on our Platform (whether in writing, orally or by any other means) by or on behalf of the user to us and which if disclose in tangible or intangible form is marked confidential (including in visual, oral, or electronic form) relating to us or any other user that was previously an user, which is provided or disclosed through us (or to any employees or agents) in connection with the use or participate in KCCP Services via the KCCPAD Platform.</p>
                <p style={{ paddingLeft: '30px' }}><strong>"Content"</strong><span>&nbsp;</span>refers to all content generated by us , including logos, identifying marks, images, illustrations, designs, icons, photographs, videos, text, any written or multimedia materials, services, advertisements, software, code, data, files, archives, folders, or available downloads on the KCCPAD Platform.</p>
                <p style={{ paddingLeft: '30px' }}><strong>“Cookie”<span>&nbsp;</span></strong>refers to the small text files that are placed on your computer by the website that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owner of the site.</p>
                <p style={{ paddingLeft: '30px' }}><strong>“CrossSwap”</strong><span>&nbsp;</span>refers to the cross-chain swap designed to unify the trading service on one platform. Please find further details at<span>&nbsp;</span><a href="https://crossswap.com/">https://crossswap.com/</a>.&nbsp;&nbsp;</p>
                <p style={{ paddingLeft: '30px' }}><strong>“CrossWallet”</strong><span>&nbsp;</span>refers to the non-custodian wallet service that holds any digital asset you prefer to hold, trade, or send, from tokens to NFTs, which it works seamlessly move between different blockchain and works on any device, either mobile or web. Please find further details at<span>&nbsp;</span><a href="https://crosswallet.app/">https://crosswallet.app/</a>.</p>
                <p style={{ paddingLeft: '30px' }}><strong>“</strong><strong>ETH</strong><strong>PAD”<span>&nbsp;</span></strong>refers to the platform for Ethereum Smart Chain Network, which is a platform for retail investment into tokens offered via IDO. Please find further details at<span>&nbsp;</span><a href="https://ethpad.network/">https://ethpad.network/</a>.</p>
                <p style={{ paddingLeft: '30px' }}><strong>“IDO”<span>&nbsp;</span></strong>refers to Initial DEX Offerings or Initial Decentralized Exchange Offerings.</p>
                <p style={{ paddingLeft: '30px' }}>“<strong>KCCPAD Token(s)</strong>” refers to a blockchain - based token which is issued, stored, transferred, transacted on the KuCoin Community Chain blockchain. KCCPAD Token will be issued as a KRC-20 Token. To gain access to Guaranteed Allocation rounds for the IDO Project, user needs to stake KCCPAD Token on the KCCPAD Platform.</p>
                <p style={{ paddingLeft: '30px' }}>“<strong>KRC-20 Token</strong>” refers to a blockchain-based token which is issued, stored, transferred, and transacted on the KuCoin Community Chain blockchain.</p>
                <p style={{ paddingLeft: '30px' }}><strong>“KCCPAD Platform”<span>&nbsp;</span></strong>refers to the Website, or platform that facilitates token swaps, and where the Project is officially launched, and makes available its native Tokens (“<strong>Pool</strong>”) to be distributed to users in the allocation rounds.</p>
                <p style={{ paddingLeft: '30px' }}><strong>“NFTLaunch”</strong><span>&nbsp;</span>refers to the platform for deflationary NFT Launchpad which is the first platform to offer zero gas wars, fair distribution and free NFT airdrops of new and upcoming NFT projects. Please find further details at<span>&nbsp;</span><a href="https://nftlaunch.network/">https://nftlaunch.network/</a>.</p>
                <p style={{ paddingLeft: '30px' }}><strong>“Partners’’</strong><span>&nbsp;</span>refers to us, BSCPAD, ETHPAD, TRONPAD, ADAPAD, VELASPAD, NFTLaunch, CrossWallet, CrossSwap, KCCSwap, and WagyuSwap.</p>
                <p style={{ paddingLeft: '30px' }}><strong>"Prohibited Jurisdictions"<span>&nbsp;</span></strong>specifically refer to Albania, Barbados, Botswana, Burkina Faso, Cambodia, Democratic People’s Republic of Korea, Haiti, Iran, Jamaica, Morocco, Myanmar (Burma), Nicaragua, Pakistan, Panama, Philippines, Senegal, South Sudan, Syria, Thailand, Uganda, Yemen, Zimbabwe, and the United States of America.</p>
                <p style={{ paddingLeft: '30px' }}>“<strong>Project</strong>” refers to the project being launched for IDO event on the KCCPAD Platform.</p>
                <p style={{ paddingLeft: '30px' }}>“<strong>Services</strong>” refer to the services provided to the users through the website, which include the accessibility to the new IDO token offered from each IDO fundraising project, staking service, Bridge Service and other relevant services available through the Platform and all free trials and beta services made available via the KCCPAD Platform by us, which may be subject to a periodic revision.</p>
                <p style={{ paddingLeft: '30px' }}>“<strong>Staking</strong><span>&nbsp;</span><strong>or Stake</strong>” refers to the staking or deposit service in which the users can delegate or deposit the user’s KCCPAD Tokens in exchange for an opportunity to participate in the Services, and IDO Project.</p>
                <p style={{ paddingLeft: '30px' }}><strong>“TRONPAD”<span>&nbsp;</span></strong>refers to the platform for Tron Smart Chain Network, which is a platform for retail investment into tokens offered via IDO. Please find further details at<span>&nbsp;</span><a href="https://tronpad.network/">https://tronpad.network/</a>.</p>
                <p style={{ paddingLeft: '30px' }}><strong>“VELASPAD”<span>&nbsp;</span></strong>refers to the platform for Velas Chain Network, which is a platform for retail investment into tokens offered via IDO. Please find further details at<span>&nbsp;</span><a href="https://velaspad.io/">https://velaspad.io/</a>.</p>
                <p style={{ paddingLeft: '30px' }}><strong>“WagyuSwap”<span>&nbsp;</span></strong>refers to the Decentralized Exchange build on Velas Blockchain, an entirely new blockchain adapted from Solana which guaranteed full EVM compatibility. Please find further details at<span>&nbsp;</span><a href="https://wagyuswap.app/">https://wagyuswap.app/</a><span>&nbsp;</span>.</p>
                <p style={{ paddingLeft: '30px' }}><strong>"Wallet(s)"<span>&nbsp;</span></strong>refers to any digital wallet which is compatible with KuCoin Community Chain Network, Ethereum blockchain and ERC-20 smart contracts, such as MetaMask.</p>
                <p style={{ paddingLeft: '30px' }}>&nbsp;</p>
                <h3>3. General Provisions</h3>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>3.1 Contractual Relationship</span></p>
                <p style={{ paddingLeft: '30px' }}>These Terms constitute a valid and binding agreement between Parties. The binding obligations stipulated in these Terms are enforceable.</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>3.2 Revision and Amendments</span></p>
                <p style={{ paddingLeft: '30px' }}>We reserve the right to revise, amend, or update any clauses and provisions stipulated in these Terms in our sole discretion at any time. We will notify the revision or amendment of such clauses or provisions by updating these Terms and specify the<span>&nbsp;</span><em>'Last Revised Date</em>' displayed on a page of Terms and Conditions. Any revisions and updates on these Terms will be automatically effective upon their publication on the KCCPAD Platform, which includes the Website. Therefore, your continuity of accessing or using the KCCPAD Platform, and Services will be deemed that you agree to have read, understood, and accepted all revised terms and provisions. If you do not agree on any revised or updated terms and provisions, you should immediately stop accessing or using our Services. You are encouraged to frequently and carefully review these Terms to ensure that you understand these Terms.</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>3.3 Privacy Policy</span></p>
                <p style={{ paddingLeft: '30px' }}>You acknowledge and confirm that you have read, understood, and agreed to provisions stipulated in the Privacy Policy, which will explain how we treat your information and protects your privacy when accessing or using the KCCPAD Platform.</p>
                <p style={{ paddingLeft: '30px' }}>By using KCCPAD Platform, you hereby agree that we may collect, use your information, and anonymised data pertaining to your use of the Services for analytics, trends’ identification, and purpose of statistics to further enhance the effectiveness and efficiency of the Services.</p>
                <p style={{ paddingLeft: '30px' }}>You hereby expressly authorise us to disclose any and all information relating to you in our possession to any law enforcement or government officials upon the request by the court order.</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>3.4 Links to and from the Website</span></p>
                <blockquote style={{ margin: '0 0 0 40px', border: 'none', padding: '0px' }}>
                  <p style={{ paddingLeft: '30px' }}>3.4.1 You may, through hypertext or other computer links, gain access form the website to websites operated or made available, or otherwise licensed by persons other than us (“<strong>Third-Party Services</strong>”). Such hyperlinks are provided for your convenience.</p>
                  <p style={{ paddingLeft: '30px' }}>3.4.2 A link from Third-Party Services does not mean that we endorse or approve the content on such website or does not mean we are an operator of that website. You understand that you are solely responsible for determining the extent to which you may use or rely upon any content at any other Third-Party Services websites which you have accessed from the Website. We have no control over the content of these sites or resources and accept no liability for them or for any loss or damage that may arise from your use of them.</p>
                  <p style={{ paddingLeft: '30px' }}>3.4.3 We assume no responsibility for the use of, or inability to use, any Third-Party Services’ software, other materials, or contents posted and/or uploaded on such website and we will have no liability whatsoever to any person or entity for any inaccuracy or incompleteness of such third-party content. All intellectual property rights in and to Third-Party Services are property of the respective third parties.</p>
                </blockquote>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>3.5 Disclaimer for Accessibility of the Website and the Services</span></p>
                <blockquote style={{ margin: '0 0 0 40px', border: 'none', padding: '0px' }}>
                  <p style={{ paddingLeft: '30px' }}>3.5.1 The Website merely facilitates the users’ accessibility to the IDO Project tokens and does not provide you with any warranty or representation whatsoever regarding its quality, value, specification, fitness for the purpose, completeness or accuracy of its technology or infrastructure of the tokens.</p>
                  <p style={{ paddingLeft: '30px' }}>3.5.2 We will make all commercially reasonable attempts to facilitate information about the Project on the KCCPAD Platform. However, you hereby acknowledge that We do not guarantee the accuracy, timeliness, or completeness of such information, and does not provide any warranty in connection with your use or reliance on such information. You agree that your use of the Project information will be at all your own risk. We will not be liable to you in any manner for the termination, interruption, delay, or inaccuracy of any Project information launched on the KCCPAD Platform.</p>
                  <p style={{ paddingLeft: '30px' }}>3.5.3 To the extent permitted by applicable laws, the Website and the Services are provided on an ‘AS IS’ and ‘AS AVAILABLE’ basis. We do not warrant that the features, and functions contained on the Website and the Services will satisfy your preferences. You hereby agree and acknowledge that your access and use of the Website and the Services are at your own risk, and you will be liable for any responsibility, consequences that may arise out of or in connection with the usage or accessibility of the Website and the Services. You expressly agree that we will have absolutely no liability in this regard.</p>
                  <p style={{ paddingLeft: '30px' }}>3.5.4 You hereby agree and acknowledge that the Website may contain links to a third-party website or services that are not owned or even controlled by us, thus we will hold no responsibility for the content or services, goods, or activities provided by such third-party website. You further agree that we have no liability directly or indirectly for any damage, loss, or consequence arising out of or in connection with the usage or reliance of any content, material, services available through the third-party website.</p>
                  <p style={{ paddingLeft: '30px' }}>3.5.5 We reserve the right to limit the availability of the Website to any person, geographic area, or jurisdiction we so desire and/or terminate your access to and use of the Website and the Services, at any time and in our sole discretion.</p>
                  <p style={{ paddingLeft: '30px' }}>3.5.6 We may, at our sole discretion, impose limits or restrictions on the use you make of the Website. Further, for commercial, security, technical, maintenance, legal or regulatory reasons, or due to any breach of these Terms, we may withdraw the Website or your access to the Website and the Services at any time and without notice to you.</p>
                </blockquote>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>3.6 Information</span></p>
                <p style={{ paddingLeft: '30px' }}>You acknowledge that you are solely responsible for any submissions of all contents, remarks, suggestions, ideas, materials, feedbacks, or other information, including bug reports in relation to the Services provided through the Website including any submission to our social media platforms such as Twitter and Telegram, and you, not us, have full responsibility for such submissions, including their accuracy, legality, reliability, appropriateness, originality, and copyright. We shall reply on the information you have provided and will not verify it. Notwithstanding the foregoing, we have the right to refuse to post, remove, edit, or abridge any submission for any reason and to freely use, copy, disclose, publish, display, or exploit such submission as we deem necessary without any payment of royalty, acknowledgement prior to consent, we may retain copies of all information materials relevant to the Service.</p>
                <p style={{ paddingLeft: '30px' }}>&nbsp;</p>
                <h3>4. Intellectual Property</h3>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>4.1&nbsp;All present and future copyright, title, interests in and to the Services, registered and unregistered trademarks, design rights, unregistered designs, database rights and all other present and future intellectual property rights and rights in the nature of intellectual property rights that exist in or in relation to the use and access of the Website and Services are owned by or otherwise licensed to Subject to your compliance with these Terms, we grant you a non-exclusive, non-sub license, and any limited license to merely use or access the Website and the Services in the permitted hereunder.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>4.2 Except as expressly stated in these Terms, nothing in these Terms should be construed as conferring any right in or license to us or any other third party’s intellectual rights.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>4.3 If and to the extent that any such intellectual property rights are vested in you by operation of law or otherwise, you agree to do any and all such acts and execute any and all such documents as we may reasonably request in order to assign such intellectual property rights back to us.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>4.4 You agree and acknowledge that all Website content must not be copied or reproduced, modified, redistributed, used, created for derivative works, or otherwise dealt with for any other reason without being granted a written consent from us.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>4.5 Third parties participating on the Website may permit us to utilise trademarks, copyrighted material, and other intellectual property associated with their businesses. We will not warrant or represent that the content of the Website does not infringe the rights of any third party.</span></p>
                <p style={{ paddingLeft: '30px' }}>&nbsp;</p>
                <h3>5. Participation in the Services</h3>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>5.1 By using or accessing the Services, you represent and warrant that you understand that there are inherent risks associated with digital currencies, and the underlying technologies, including, without limitation, cryptography and blockchain, and you agree that we, the community, and open-source code contributors are not responsible for any loss or damage associated with these risks.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>5.2 You specifically acknowledge and agree to follow the conditions stipulate herein the Website, such as tier system, Staking/Unstaking and other conditions which we may update from time to time.</span></p>
                <p>&nbsp;</p>
                <h3>6. Bridge Service</h3>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>6.1 Upon completion of the registration and identity verification for your Wallet, you may use the Services made available by the Partners, including but not limited to, Staking, and accessing to other information released by us, participating in any launchpad, token swaps, bridge, etc., in accordance with the provision of the bridge function (“Bridge Service”).</span></p>
                <p style={{ paddingLeft: '30px' }}>Under the bridge function, you will be required to conduct the following provisions for the purpose of double verification and convenience crossing between two platforms:</p>
                <blockquote style={{ margin: '0 0 0 40px', border: 'none', padding: '0px' }}>
                  <p style={{ paddingLeft: '30px' }}>Connect your Wallet (by clicking the button shown on the Website) with our Partner’s service; and</p>
                  <p style={{ paddingLeft: '30px' }}>Synchronize your KYC/AML/CFT with our Partners;</p>
                </blockquote>
                <p style={{ paddingLeft: '30px' }}>You hereby acknowledge and agree that we will not be liable for any damage, loss, consequence, or devaluation of the assets held in any of your Wallet under the provision of Partners’ service, which may occur as a result or in connection with any matter related to disruptive function, broken internet system or any failure.</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>6.2 In term of KYC/AML/CFT synchronize, you understand and acknowledge that you agree to share your KYC/AML/CFT information among our Partners for the purpose of identity verification through KYC/AML/CFT process. Therefore, you hereby agree that when you use our synchronize service, you will inform us for any changed KYC/AML/CFT information which was previously provided to the KCCPAD Platform (if any).</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>6.3 Upon sending an instruction of our service for KYC/AML/CFT synchronize, your account will be immediately updated to reflect the KYC/AML/CFT profile, and your KYC/AML/CFT profile will be included in Partner’s data to match the identity verification through KYC/AML/CFT requirement. Once the instruction is executed, your account will be updated to reflect that the instruction has been fully match and passed the KYC/AML/CFT verification and you are ready to experience our service. The instruction will remain uncompleted until it is fully filled with the Wallet address. To provide a Wallet address, you authorized us and our Partners to reciprocally confirm and temporarily control the data transfer to us or our Partner between each other.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>6.4 You are only allowed one time to synchronize KYC/AML/CFT to any launchpad provided by Partners. Double check the address input in the box, whether it is the address showing in your Wallet. If you need to synchronize again or update any information, please contact our supporting team, detail provided in Clause 15.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>6.5 Unless otherwise specified by us via the KCCPAD Platform, to use the Bridge Service, you must complete the identification verification through KYC/AML/CFT process or passed the KYC/AML/CFT synchronized from any Partners for your account on the KCCPAD Platform. You acknowledge and agree that:</span></p>
                <blockquote style={{ margin: '0 0 0 40px', border: 'none', padding: '0px' }}>
                  <p style={{ paddingLeft: '30px' }}>a. you fully understand the swapping process, including but not limited to the risk or major fluctuation of digital asset in swapping, and the risk of exacerbated adverse outcome when leverage is used;</p>
                  <p style={{ paddingLeft: '30px' }}>b. you have sufficient technological knowledge and experience and the capacity to understand the process and agree to independently assume all the risks arising from the Bridge Service; and</p>
                  <p style={{ paddingLeft: '30px' }}>c. you agree and authorized us to take various reasonable measures in our discretion (including but not limited to blocking or freezing the swapping requests under specific circumstances) in accordance with our decision to protect the legitimate interests of you, us, especially on the KCCPAD Platform, and other users.</p>
                </blockquote>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>6.6 Prior to conducting the Bridge Service for swap your asset, in accordance with type of asset swapping, you shall provide the amount for swapping that shall comply with the daily limit (show on the BRIDGE page). Unless otherwise specified by us via the KCCPAD Platform to use the Bridge Service, you agree to keep enough asset in your Wallet, as required by engaging in the swapping process. Failure to keep enough assets may result in cancellation of the swapping request.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}><br />
                </span></p>
                <h3>7. Identity Verification through KYC/AML/CFT Process</h3>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>7.1 KCCPAD Platform is a permissionless and fully decentralised platform for token swaps. As a software development company, we have no role in enforcing KYC/AML/CFT by default, however, we have a mandatory requirement for identification verification KYC/AML/CFT tools for the IDO fundraising companies to enforce on their users, which IDO companies have to comply with the mandatory. KCCPAD Platform is implementing KYC/AML/CFT tools into our launchpad through<span>&nbsp;</span><a href="https://kycaid.com/">https://kycaid.com/</a>.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>7.2 The use of KYC/AML/CFT tools by IDO fundraising companies using the Services on the KCCPAD Platform is not the discretion of said entitles, and they must require you to complete KYC/AML/CFT verification process before participating in any Projects.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>7.3 Although we make no warranty as to the merit, legality, or juridical nature of any IDO tokens, we nonetheless understand the need of Project to require KYC/AML/CFT verification on their token sale participants of users. Therefore, we reserve the right:</span></p>
                <blockquote style={{ margin: '0 0 0 40px', border: 'none', padding: '0px' }}>
                  <p style={{ paddingLeft: '30px' }}>7.3.1 at any time, to ask for your personal information, name-surname, birthday,-e- mail address, nationality, location, government identification number (identification card/passport number and date of identification card/passport issuing), telegram username, Wallet address, and any KYC/AML/CFT documentation with the liveness test that it deems necessary to determine the identity and location of a user, and reserves the right to restrict Service and payment until identity is sufficiently determined;</p>
                  <p style={{ paddingLeft: '30px' }}>7.3.2 The liveness test shall mean taking a photo of your government identification with your phone or camera. Then, also take a photo of yourself (selfie of your face) holding your ID document and the paper you wrote on next to your face (not covering your face), the piece of paper shall write down the (1) KCCPAD, (2) the current date, and (3) the last 4 characters of your Wallet address”.</p>
                  <p style={{ paddingLeft: '30px' }}>7.3.3 to share the submitted KYC/AML/CFT information and documentation to the third parties to verify the authenticity of submitted information, and the end user (you) agree to this by using the Service;</p>
                  <p style={{ paddingLeft: '30px' }}>7.3.4 to reject the use of the Service that we have the reasonable ground to believe that they are found to be in violation of relevant and applicable AML/CFT laws and regulations, and to cooperate with the competent authorities or any investigation when and if necessary upon the valid request by the court order.</p>
                </blockquote>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>7.4 We expressly prohibit and reject the use of the Service for any form of illicit activity, including money laundering, terrorist financing or trade sanctions violations, consistent with various jurisdictions’ laws, regulations and norms. To that end, the Service is not offered to individuals or entities on any Politically Exposed Persons (“PEP”) lists, or subject to any United States, European Union, or other global sanctions or watch lists. By using the Service, you represent that you are not on any such lists.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>7.5 You fully acknowledge that your information and KYC/AML/CFT documentation may be disclosed to government agencies or regulator upon the only valid request of the court order. Once you have decided to participate in any Project and start staking your KCCPAD Tokens, you must ensure that all information provided to us via the KCCPAD Platform is complete, accurate, and updated in a timely manner. We will rely on the information you provided and should there be any reasonable grounds to believe that the partial or the whole of your information provided to us is incomplete, or incorrect, or outdated, we reserve the right to send you a notice to demand correction, or to delete such information directly, and, as the case may be, to prohibit you to access to all or part of the Website and the Services.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>7.6 If we have the reasonable ground to believe that any user transacts or use the Services by using the digital currencies derived from any suspicious illegal activities, we shall be entitled to freeze/close/delete the user’s accounts as necessary. We will hold no liability to such users for any damage, or loss arising out of or in connection with this manner herein. Please note that any attempt to circumvent this Clause 7 will also result in a similar action.</span></p>
                <p>&nbsp;</p>
                <h3>8. Misuse of the Website</h3>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>8.1 In the event of any misuse and/or abuse of the Website or breach any provision in these Terms, we reserve the right to block your access to the Website and other until the matter is solved.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>8.2 Use of the Website for transmission, publication or storage of any material on or via the Website which is in violation of any applicable laws or regulations or any third-party’s rights is strictly prohibited, including but not limited to the use of the Website or the transmission, distribution, publication or storage any material on or via the Website in a matter or for the purpose which infringes copyright, trademark, trade secret or other intellectual property rights, is obscene or harmful to minors or constitutes an illegal act or harassment, is libellous or defamatory, violates any privacy or data protections laws, is fraudulent or breaches any exchange control laws.</span></p>
                <p>&nbsp;</p>
                <h3>9. Representations and Warranties</h3>
                <p>You hereby agree to make the following representations and warranties by accessing to the Website and/or using the Services:</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>9.1 You have full capacity and authority under the application laws to agree and bind yourself to these Terms.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>9.2 You are eighteen years of age or older.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>9.3 You are not a citizen or a resident from the Prohibited Jurisdictions, and you do not have any relevant connection with any jurisdictions where we have prohibited Services and access to the Website.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>9.4 You are aware and agree to comply with all applicable domestic and international laws, statutes, ordinances, and regulations applicable to your use of the Website and the Services. Your use of the Website and the Services are not for any unlawful or illegal purposes, including but not limited to the usage against the copyright laws, AML/CFT laws.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>9.5 You are the exclusive owner of KCCPAD Tokens and your other cryptocurrencies held in your Wallet. The tokens maintained in your Wallets are not and will not be derived from money laundering, terrorist financing, fraud, or any other illegal activities under any applicable laws. You further hereby acknowledge and agree that we will not be responsible for actions taken by you that result in the loss or destruction of the value of the tokens and rewards you hold in the Wallet.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>9.6 You validly undertake any action or enter into any transaction with regard to these Terms. You are solely responsible for use of the Website and the Services for all activities, or transactions that occur on or through your user account on the KCCPAD Platform.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>9.7 You will provide only accurate, complete, and up-to-date information and documents, if any, for the purposes of accessing or using or participating the Services on the KCCPAD Platform. You will further agree to put your effort to ensure that the confidentiality of your personal or credential information, including your Wallet address is restricted, and safely maintained to your device you use to access the Website.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>9.8 You will be acknowledged and agree that if you lose access to the Wallet that you connected with the KCCPAD Platform, we will not be able to help you recover these lost, or transfer IDO tokens or any KCCPAD Tokens back to your Wallet. It will be your solely responsibility to manage your KCCPAD Platform’s account, and private key.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>9.9 You will be responsible for obtaining the data network access necessary to use the Website. Your network's data and rates and fees may apply if you access or use the Website from a wireless-enabled device, and you will be responsible for such rates and fees.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>9.10 You will understand and be aware of risks associated with accessing or using or participating in the Services, and you will be fully liable at your own risk.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>9.11 You will be aware that you are subject to tax regulations in the jurisdiction you reside in and will be fully responsible for filling or reporting any taxes and paying them as required by the Applicable Laws. We will not be liable to compensate you for your tax obligations or advise you in relation to your tax obligations. Any uncertainties and unpredictable matters in tax legislation with respect to any tokens may expose you to any unknown or unforeseeable tax implications associated with your holding of tokens and the use of the Services for which we will have no liability. Moreover, you will not hold us liable for any expenses or losses resulting from unknown or unforeseeable tax implications.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>9.12 You will not breach any of the provisions stipulated in these Terms, the Privacy Policy, or any Applicable Laws in any relevant jurisdictions.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>9.13 You will not use the Website and the Services in one of any following manners, except as expressly permitted in these Terms, and our discretion.</span></p>
                <p style={{ paddingLeft: '30px' }}>You will not:</p>
                <blockquote style={{ margin: '0 0 0 40px', border: 'none', padding: '0px' }}>
                  <p style={{ paddingLeft: '30px' }}>(a) infringe any propriety rights, including but not limited to copyrights, patents, trademarks, or trade secrets of us.</p>
                  <p style={{ paddingLeft: '30px' }}>(b) use the Website or the Services to transmit any data or send or upload any material or content that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware, or any other harmful programmes or similar computer code designed to adversely affect the operation of the Website and the Services provided on the Platform.</p>
                  <p style={{ paddingLeft: '30px' }}>(c) expressly or impliedly, use the Website and the Services that is deemed unlawful, offensive, malicious, threatening, libellous, defamatory, obscene, or otherwise objectionable or violates these Terms, or any other party's intellectual property.</p>
                  <p style={{ paddingLeft: '30px' }}>(d) modify, make any back-up or archival copies of the KCCPAD Platform or any part thereof including disassembling, and you will also not adapt, hack the Website or modify another website to imply that it is associated with the Website falsely.</p>
                  <p style={{ paddingLeft: '30px' }}>(f) crawl, scrape, or otherwise cache any content from the Website, and you will agree not to use any automated data collection methods, data mining, robots, or scraping or any data gathering methods of any kind on the Website.</p>
                  <p style={{ paddingLeft: '30px' }}>(g) use the Website or any of its contents for advertising or soliciting, for any other commercial, political, or religious purpose, or to compete, either directly or indirectly with us.</p>
                </blockquote>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>9.14 You will defend, indemnify, and not hold us, the Partners, our Affiliates, each of our respective employees, officers, directors, and representatives liable to and against any claims, damages, losses, liabilities, costs, and expenses (including reasonable attorney's fees), arising out of or relating to any third-party claim concerning these Terms, or your use of the Services in violation of these Terms and Applicable Laws.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>9.15 Each of the user’s Representations and warranties under this Clause 9 shall survive and continue to remain in full force and effect after the termination and/or execution of these Terms.</span></p>
                <p>&nbsp;</p>
                <h3>10. Risk Disclosure</h3>
                <p>By accessing the Website or using or participating in our Services, you expressly acknowledge and assume the following risks:</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>10.1 Risk of loss in value</span></p>
                <p style={{ paddingLeft: '30px' }}>Tokens or any digital currencies are not issued by any central banks or national, supra-national, or quasi-national organizations. They are also not backed by any hard assets or other credit. The value of tokens or any digital currencies is affected by several factors, including but not limited to, the total number of tokens or any digital currencies in existence, the continued willingness of market participants to exchange government-issued currency for tokens or digital currencies , purchasers' expectations with respect to the rate of inflation of fiat currencies, purchasers' expectations with respect to the rate of deflation of cryptocurrencies, interest rates, currency exchange rates, cyber theft of cryptocurrencies from online digital wallet providers, or news of such theft from such providers or individuals' digital wallets, investment and trading activities of large investors, monetary policies of the governments, trade restrictions, currency devaluations and revaluations, regulatory measures, the global or regional political, economic or financial events and situations. Thus, all these factors will affect the value of tokens or digital currencies, which may result in the permanent partial or total loss of the value of a particular tokens or digital currencies. No one will be obliged to guarantee the liquidity or the market price of any of the tokens or digital currencies maintained into your Wallets. The volatility and unpredictability of the value of tokens or digital currencies relative to the government-issued currency may result in a significant loss over a short period of time.</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>10.2 The regulatory regime governing tokens or digital currencies</span></p>
                <p style={{ paddingLeft: '30px' }}>The regulatory framework relating to tokens or digital currencies remains unsettled, and any laws, regulations, or guidelines may be significantly revised and amended which will materially and adversely affect the value of tokens or digital currencies and your Services on the Website or the KCCPAD Platform.</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>10.3 Technical and System Failure affected the obligations stipulated in these Terms which we may experience system failures, unplanned interruptions in our KuCoin Community Chain or the Services, hardware or software defects, security breaches or other causes that could adversely affect our infrastructure network, which includes the Website and the KCCPAD Platform.</span></p>
                <p style={{ paddingLeft: '30px' }}>We are unable to anticipate the occurrence of hacks, cyber-attacks, mining attacks, including but not limited to double-spend attacks, majority mining power attacks and selfish-mining attacks, distributed denial of service attacks or errors, vulnerabilities or defects in the Website, KCCPAD Tokens, users' Wallets or any technology, including but not limited to smart contract technology. Also, We are unable to detect the hacks as mentioned earlier, mining attacks, cyber-attacks, distributed denials of service errors vulnerabilities, or defects in a timely manner and does not have sufficient resources to efficiently cope with multiple incidents happening simultaneously or in rapid succession on the KCCPAD Platform.</p>
                <p style={{ paddingLeft: '30px' }}>In addition, our infrastructure network or Services could be disrupted by numerous events, including natural disasters, equipment breakdown, network connectivity downtime, power losses, or even intentional disruptions of our Services, such as disruptions caused by software viruses or attacks by unauthorized users, some of which are beyond our control. Although we have taken steps and used our best endeavour against malicious attacks on our appliances or our infrastructure, which are critical for the maintenance of the KCCPAD Platform and the Services, there can be no assurance that cyber-attacks, such as distributed denials of service, will not be attempted in the future, and that our enhanced security measures will be effective. Any significant breach of our security measures or other disruptions resulting in a compromise of the usability, stability and security of our infrastructure network or Services, including the KCCPAD Platform, may adversely affect KCCPAD Tokens.</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>10.4 We will have no liability for any delay, error, interruption, or failure to perform any obligation under these Terms where the delay or failure is directly or indirectly resulting from any causes beyond our control, including, but not limited to:</span></p>
                <blockquote style={{ margin: '0 0 0 40px', border: 'none', padding: '0px' }}>
                  <p style={{ paddingLeft: '30px' }}>10.4.1 Acts of God, nature, or court of government;</p>
                  <p style={{ paddingLeft: '30px' }}>10.4.2 Failure or interruption of public or private telecommunication networks, the failure of KuCoin Community Chain blockchain, communication channels or information systems;</p>
                  <p style={{ paddingLeft: '30px' }}>10.4.3 Acts or omission of acts of a party for whom we are not responsible;</p>
                  <p style={{ paddingLeft: '30px' }}>10.4.4 Delay, failure, or interruption in, or unavailability of, Third-Party Services;</p>
                  <p style={{ paddingLeft: '30px' }}>10.4.5 Strikes, lockouts, labour disputes, wars, terrorist acts and riots.</p>
                </blockquote>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>10.5 YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES AND THE WEBSITE IS COMPLETELY AT YOUR OWN RISK. THIS CLAUSE IS NOT EXHAUSTIVE AND DOES NOT DISCLOSE ALL THE RISKS ASSOCIATED WITH DIGITAL CURRENCIES AND THE USE OF SERVICES. THEREFORE, YOU ARE RECOMMENDED TO CAREFULLY CONSIDER WHETHER SUCH USE IS SUITABLE FOR YOU IN LIGHT OF YOUR JUDGEMENT, CIRCUMSTANCES, AND FINANCIAL POSITION.</span></p>
                <p>&nbsp;</p>
                <h3>11. Limitation of Liability</h3>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>11.1 NOTWITHSTANDING ANY PROVISIONS WITHIN THESE TERMS, IN NO EVENT WILL US, OUR AFFILIATES, OR OUR EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE TO THE USER FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, INDIRECT, OR CONSEQUENTIAL DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR OTHER SUCH WEBSITES, OR ANY OTHER SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE, OR OTHER SUCH WEBSITES, INCLUDING, BUT NOT LIMITED TO, LOST REVENUE, LOST PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, LOSS OF YOUR CREDENTIAL INFORMATION, LOSS OR INTERRUPTION OF TECHNOLOGY, LOSS OF USE OF SERVICE OR EQUIPMENT, EVEN IF THE USER WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND WHETHER ARISING UNDER A THEORY OF CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE. WE WILL ALSO HAVE NO LIABILITY FOR ANY EMERGENCY AND/OR UNFORESEEABLE INCIDENTS RELATED TO YOUR USE OF THE SERVICES ON THE KCCPAD PLATFORM SUCH AS STOLEN PRIVATE KEY, OR HACKED ACCOUNTS.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>11.2 Except as expressly provided in these Terms, and to the maximum extent permitted by any Applicable Laws, we disclaim all other representations or warranties, express or implied, made to you, your affiliates, or any other person, including, without limitation, any warranties regarding the quality, suitability, merchantability, fitness for a particular purpose or otherwise (regardless of any course of dealing, custom or usage of trade) of any service provided incidental to the Services under these Terms.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>11.3 In no event will our aggregate liability for any loss or damage that arises in connection with the Services exceed the purchase amount you paid to participate in the KCCPAD Platform, if any, during a twelve (12) month period immediately preceding the event that gave rise to the claim for liability. The preceding limitations of liability will apply to the fullest actual amount you paid to participate or access in the KCCPAD Platform.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>11.4 Except as expressly provided in these Terms, and to the fullest extent permitted by any Applicable Laws, us, our Affiliates, and our related parties each disclaim all liability to you for any loss or damage arising out of or due to:</span></p>
                <blockquote style={{ margin: '0 0 0 40px', border: 'none', padding: '0px' }}>
                  <p style={{ paddingLeft: '30px' }}>11.4.1 your use of, inability to use, or availability or unavailability of the Services, including any Third-Party Services made available through the Services;</p>
                  <p style={{ paddingLeft: '30px' }}>11.4.2 the occurrence or existence of any defect, interruption, deletion of files, delays in the operation or transmission of information to, from, or through the Services, communications failure, theft, destruction or unauthorised access to our records, programs, Services, server, or other infrastructure relating to the Services;</p>
                  <p style={{ paddingLeft: '30px' }}>11.4.3 the Services being infected with any malicious code or viruses; or</p>
                  <p style={{ paddingLeft: '30px' }}>11.4.4 the failure of the Services to remain operational for any period of time.</p>
                </blockquote>
                <p>&nbsp;</p>
                <h3>12. Indemnification</h3>
                <p>You irrevocably undertake the responsibility of fully indemnifying and holding harmless each of us, our Affiliates, licensors, shareholders, officers, directors, managers, employees, and agents from and against any and all losses, claims, actions, proceedings, damages, demands, judgements, sums, liabilities, damages, costs, charges and expenses, including, but not limited to, any reasonable attorney's fees or penalties imposed by any regulatory authority, and reimbursements arising out of or related to the following situations:</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>12.1 Your use or any person using the Services on your behalf or participation in accordance with the Services on the Website or the KCCPAD Platform;</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>12.2 Your breach of or our enforcement of these Terms;</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>12.3 Any violations of Applicable Laws, regulation, or rights of any third-party during your use or participate in the KCCPAD Platform.</span></p>
                <p style={{ paddingLeft: '30px' }}>If you are obligated to indemnify us, our Affiliates, shareholders, licensors, officers, directors, managers, employees, and agents, we will have the right, at our sole discretion, to control any action or proceeding and to determine whether we wish to proceed, or settle, and if so, on what terms or provisions.</p>
                <p style={{ paddingLeft: '30px' }}>&nbsp;</p>
                <h3>13. Termination</h3>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>13.1 These Terms will be immediately terminated by discontinuing your use, or participate in the Services and you agree to terminate the accessibility on the KCCPAD Platform.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>13.2 These Terms can be suspended or terminated without a notice from us if there is a reasonable ground to believe that you have breached any of the terms or provisions stipulated in these Terms, or if you do not comply with these Terms.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>13.3 The termination of these Terms will not prevent us from seeking remedies from you in the case where you breach any terms or provisions before such termination. We will not be liable to you or to any third party for any termination, suspension, or modification of your access to the Services.</span></p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>13.4 Any ongoing obligation to you as well as the provisions regarding (i) Intellectual Property, (ii) indemnification, (iii) Limitation of Liability, and (iv) any other provisions designed to survive, will survive any termination or expiration of these Terms for any reason.</span></p>
                <p>&nbsp;</p>
                <h3>14. No Financial and Legal Advice</h3>
                <p>KCCPAD Platform is merely a technology platform, and we are not your broker, intermediary, agent, or legal advisor and has no fiduciary relationship or obligation to you in connection with any decisions or activities effected by you using or participating on the Website or the KCCPAD Platform. No communication or information provided to you by us is intended as or will be considered or construed as, the solicitation of an offer to buy, the investment advice, financial advice, legal advice, or any other sort of advice. All Services, Transactions, and Investments will be executed automatically based on the parameters of your consideration. You will be solely responsible for determining whether any services, or investments are suitable and match your interests according to your judgement, objectives, circumstances and risk tolerance. You will be solely responsible for any losses or liabilities therefrom.</p>
                <p>Before executing any transactions, purchasing KCCPAD Tokens or IDO tokens on the KCCPAD Platform, you should consult with your independent financial, legal, or tax professionals. We will not be liable for the decisions you make to access and purchase through the KCCPAD Platform and the Services.</p>
                <p>&nbsp;</p>
                <h3>15. Notice/Announcement</h3>
                <p style={{ paddingLeft: '30px' }}>Any notice, requests, demands, and determinations for us under these Terms (other than routine operational communications) shall be sent to support@kccpad.io</p>
                <p><br />
                </p>
                <h3>16. Governing Law, Resolving Disputes, Arbitration and Class Action Waiver</h3>
                <p>PLEASE READ THIS SECTION CAREFULLY AS IT INVOLVES A WAIVER OF CERTAIN RIGHTS TO BRING LEGAL PROCEEDINGS, INCLUDING A CLASS ACTION.</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>16.1 Notice of Claim and Dispute Resolution Period</span></p>
                <p style={{ paddingLeft: '30px' }}>Please contact us first. We will seek to address your concerns without resorting to formal legal proceedings whenever possible. If you have a dispute with us, you should contact us, and a case number will be assigned. We will attempt to resolve your dispute internally as soon as possible. The Parties will agree to negotiate in good faith to resolve the dispute and discussions will remain confidential and subject to applicable laws protecting settlement discussions from use as evidence in any legal proceeding.</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>16.2 Agreement to Arbitrate</span></p>
                <p style={{ paddingLeft: '30px' }}>&nbsp;The Parties agree that subject to Clause 16.1 above, any dispute, claim, or controversy between the Parties that arises in connection with, or relating in any way, to these Terms, or to your relationship with us as a user of the Services (whether by contract, tort, statute, fraud, misrepresentation, or any other legal theory, and whether the claims arise during or after the termination of these Terms) will be determined by a mandatory final and binding individual arbitration rather than a class action, except as set forth below under Exceptions to the Agreement to Arbitrate. The Parties further agree that the arbitrator will have the exclusive power to rule on his or her jurisdiction, including, without limitation, any objections with respect to the existence, scope, or validity of the Agreement to Arbitrate, or to the arbitrability of any claim or counterclaim. Arbitration is more informal than a lawsuit in court. THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED. There may be more limited discovery than in court. The arbitrator must follow this agreement and can award the same damages and relief as a court, including, if applicable, attorney fees, except the arbitrator may not award declaratory or injunctive relief benefiting anyone but the Parties to the arbitration. The arbitration provisions set forth in this Clause 16 will survive termination of these Terms.</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>16.3 Arbitration Rules</span></p>
                <p style={{ paddingLeft: '30px' }}>Any dispute arising out of or in connection with these Terms including any question regarding its existence, validity or termination, shall be referred to and finally resolved by arbitration administered by the Singapore International Arbitration Centre (“SIAC”) in accordance with the Arbitration Rules of the Singapore International Arbitration Centre ("SIAC Rules") for the time being in force, which rules are deemed to be incorporated by reference in these Terms. The seat of the arbitration shall be Singapore. The Tribunal shall consist of one arbitrator. The language of the arbitration shall be English.</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>16.4 Governing Law/ Jurisdiction</span></p>
                <p style={{ paddingLeft: '30px' }}>The governing law of the arbitration will be that of the Republic of Seychelles.</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>16.5 Confidentidentiality</span></p>
                <p style={{ paddingLeft: '30px' }}>The Parties agree that the arbitration will be kept confidential. The existence of the arbitration, any non-public information provided in the arbitration, and any submissions, orders or awards made in the arbitration will not be disclosed to any non-parties except the tribunal, the parties, their counsel, experts, witnesses, accountants and auditors, insurers and reinsurers, and any other person necessary to facilitate the arbitration. Notwithstanding the preceding, a Party may disclose information to the extent that disclosure may be required to fulfil a legal duty, protect, or pursue a legal right, or enforce or challenge an award in bona fide legal proceedings. This confidentiality provision will survive the termination of these Terms and any arbitration brought under these Terms.</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>16.6 Class Action Waiver</span></p>
                <p style={{ paddingLeft: '30px' }}>The Parties agree that any claims relevant to these Terms, or your relationship with us will be brought against the other party in arbitration on an individual basis only and not as a plaintiff or class member in a purported class or representative action. The Parties further agree to waive any right for such claims to be brought, heard, or arbitrated as a class, collective, representative, or private attorney general action, to the extent permissible by Applicable Laws. Combining or consolidating individual arbitrations into a single arbitration is not permitted without the consent of all parties involved.</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>16.7 We reserve the right to update, modify, revise, suspend, or make future changes to Clause 16.2 regarding the Parties' Agreement to Arbitrate, subject to applicable laws. You hereby consent and agree that it is your responsibility to ensure that your understanding of this Clause is up to date. Subject to applicable laws, your continued use of your account will be interpreted as your acceptance of any modifications to Clause 16 regarding the Parties' Agreement to Arbitrate. You agree that if you object to the modifications to Clause 16, we may block access to your account pending closure of your account. In such circumstances, these Terms prior to modification will remain in full force and affect the pending closure of your accessibility.</span></p>
                <p>&nbsp;</p>
                <h3>17. Miscellaneous</h3>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>17.1&nbsp;Severability</span></p>
                <p style={{ paddingLeft: '30px' }}>If any of the provisions in these Terms is found by a court of competent authority to be invalid, void, unlawful or unenforceable under any Applicable Laws, such unenforceability or invalidity will not render these Terms unenforceable or invalid as a whole, and such provisions will be deleted without affecting the remaining provisions herein.</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>17.2 Variation of Terms</span></p>
                <p style={{ paddingLeft: '30px' }}>We have the right to revise these Terms at our sole discretion at any time, and by using the Website or other KCCPAD Platform, you will be expected to review such Terms regularly to ensure that you understand all provisions stipulated in these Terms.</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>17.3 Assignment</span></p>
                <p style={{ paddingLeft: '30px' }}>We will be allowed to assign, transfer, and subcontract our rights and/or obligations under these Terms without the need to provide you any notification or acquire your consent. Nevertheless, you will not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>17.4 Entire Agreement</span></p>
                <p style={{ paddingLeft: '30px' }}>These Terms, including the Privacy Policy and any rules contained on the Website or others KCCPAD Platform, constitute the sole and entire agreement between the Parties with respect to your use of the KCCPAD Platform, and supersedes other prior or contemporaneous negotiations, discussions, agreements, understandings, representations, and warranties, both written and oral, between the Parties with respect to such subject matter.</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>17.5 No Third-Party Rights</span></p>
                <p style={{ paddingLeft: '30px' }}>Nothing in these Terms will be deemed to create any rights to any creditors or other persons, not a party hereto. Moreover, these Terms will not be construed, in any respect, to be a contract, in whole or in part, for the benefit of any third parties.</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>17.6 Clickwrap</span></p>
                <p style={{ paddingLeft: '30px' }}>We may deliver the Service through electronic means such as download links, graphical, Tools or other technologies for providing the Service for users. The user interfaces to such electronic means may require that the users agree to these Terms by checking a box, clicking a button, or continuing with the Service. If user through such action the user becomes a Party to these Terms. Such an action of acceptance shall be sufficient to bind the users to the terms and conditions herein these Terms.</p>
                <p style={{ paddingLeft: '30px' }}><span style={{ fontWeight: 'bold' }}>17.7 Waiver</span></p>
                <p style={{ paddingLeft: '30px' }}>The failure of one Party to require the performance of any provision will not affect that Party's right to require performance at any time thereafter. At the same time, the waiver of one Party to seek recovery for the other Party's violation of these Terms of any provisions of applicable terms will not constitute a waiver by that Party of any subsequent breach or violation by the other Party or of the provision itself.</p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfUsePage;
