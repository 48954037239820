require("dotenv").config();
module.exports = {

  //TESTNET
  // BACKEND_URL: "http://kccpad.myfantasy.cloud",

  // BLOCKCHAIN_NETWORK: "TESTNET",
  // BSC_EXPLORER: "https://scan-testnet.kcc.network",
  // BSC_NODE_URL: [
  //   "https://rpc-testnet.kcc.network/"
  // ],
  // KCCPAD_ADDRESS: "0x2F4b1a7219F9955429a94b1Aa08451adC5962F26",
  // STAKING_CONTRACT_ADDRESS: "0x5dFe8421F856E7aE1582BA8eb6bc532Dd88f768D",

  // BSC_KYC_ADDRESS : '0xC3E818F91b62Aa54B9a27F0f90721cF73558104E',

  // MAINNET  
  BACKEND_URL: "https://kccpad.io",

  BLOCKCHAIN_NETWORK: "MAINNET",
  BSC_EXPLORER: "https://explorer.kcc.io",
  BSC_NODE_URL: [
    "https://rpc-mainnet.kcc.network/"
  ],

  KCCPAD_ADDRESS: "0x1f0Af881b89B8d9573b691Cb65933F6D897ABa99",

  STAKING_CONTRACT_ADDRESS: "0x0156F442230ACe481F4a40835488014c637d8a9d",

  // BSC_KYC_ADDRESS : '0xC3E818F91b62Aa54B9a27F0f90721cF73558104E',

  KYC_BACKEND_URI:'https://bscpad.com',
};
