import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery';
import { get } from "lodash";
import { Link } from "react-router-dom";
import { ACTION_CONST, ROUTES } from "../../constants";
import { BSC_EXPLORER } from "../../_configs";
import { Button } from "react-bootstrap";
import { BigNumber } from "bignumber.js";
import { helpers } from "../../utils";
import { useTranslation } from "react-i18next";
// import AOS from 'aos';
// import 'aos/dist/aos.css';

const UnStakingTab = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [unStakeCurrentStep, setUnStakeCurrentStep] = useState(1);
    const isConnectWallet = useSelector((state) => get(state, "utils.isConnectWallet", false));
    const walletUtils = useSelector((state) => get(state, "utils.walletUtils", null));
    const stakingInfo = useSelector((state) => get(state, "wallet.stakingInfo", {}));
    const stakingWalletInfo = useSelector((state) => get(state, "wallet.stakingWalletInfo", {}));
    const kcsBalance = useSelector((state) => get(state, "wallet.kcsBalance", 0));

    const [acceptTerm, setAcceptTerm] = useState(false);
    const [enableUnStakeBtn, setEnableUnStakeBtn] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");
    const [unStakeAmount, setUnStakeAmount] = useState('0');
    const [stakedDuration, setStakedDuration] = useState(0);
    const [totalPctFee, setTotalPctFee] = useState(0);
    const [isMaxAmount, setIsMaxAmount] = useState(false);

    const [isSubmitOK, setIsSubmitOK] = useState(false);


    useEffect(() => {

        setUnStakeStepActive();

    }, [unStakeCurrentStep])

    const cleanValue = () => {
        setUnStakeCurrentStep(1);
      };
      useImperativeHandle(ref, () => {
        return {
         cleanValue: cleanValue
        }
     });

    //enable unstake button
    useEffect(() => {
        if (unStakeCurrentStep === 2) {
            if (acceptTerm &&
                isConnectWallet &&
                !stakingInfo["isStakingPaused"] &&
                parseFloat(stakingWalletInfo["stakedAmount"]) > 0 &&
                kcsBalance > 0 &&
                stakingWalletInfo["withdrawTimestamp"] === 0
            ) {
                setEnableUnStakeBtn(true);
            } else {
                setEnableUnStakeBtn(false);
            }
        }
        if (unStakeCurrentStep === 1) setEnableUnStakeBtn(true);
        if (stakingInfo && stakingWalletInfo["stakedDuration"]) {
          setStakedDuration(stakingWalletInfo["stakedDuration"]);
          setTotalPctFee(stakingWalletInfo["totalPctFee"] / 100);
        }
    }, [acceptTerm, isConnectWallet, kcsBalance, stakingInfo, stakingWalletInfo, unStakeCurrentStep])


    const unStakeBack = () => {
        if (unStakeCurrentStep === 1) {
            return;
        } else {
            setUnStakeCurrentStep(unStakeCurrentStep - 1);
        }
    }

    const unStakeNext = () => {
        if (unStakeCurrentStep === 1) {
            setEnableUnStakeBtn(false);
            setUnStakeCurrentStep(unStakeCurrentStep + 1);
            return;
        }

        if (unStakeCurrentStep === 2 && parseFloat(unStakeAmount) === 0) {
            setEnableUnStakeBtn(false);
            setUnStakeCurrentStep(unStakeCurrentStep + 1);
            return;
        }

        if (unStakeCurrentStep === 4) {
            if (walletUtils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                walletUtils.stakingInitiateWithdrawal({ amount: isMaxAmount ? stakingWalletInfo["stakedAmount"] : unStakeAmount }, data => {
                    // console.log(data);
                    if (data.status === "STAKING_INITIATE_WITHDRAWAL_SUCCESS") {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        setIsSubmitOK(true);
                        setTransactionHash(data.txID);

                        setUnStakeCurrentStep(5);

                    }
                    if (data.status === "STAKING_INITIATE_WITHDRAWAL_FAIL") {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })

                        dispatch({
                            type: ACTION_CONST.ALERT_FAILS,
                            message: "INIT Unstake fails"
                        })
                    }
                })
            }
        } else {
            if (unStakeCurrentStep === 5) {
                return;
            } else {
                setUnStakeCurrentStep(unStakeCurrentStep + 1);
            }
        }
    }

    const handleInputUnStake = (e) => {
        setIsMaxAmount(false);

        if (helpers.isFloatFormatted(e.target.value, 4)) {
            setUnStakeAmount(e.target.value);

            //check input
            if (BigNumber(e.target.value).lte(BigNumber(stakingWalletInfo["stakedAmount"])) && BigNumber(e.target.value).gt(BigNumber(0))) {
                setEnableUnStakeBtn(true);
            } else {
                setEnableUnStakeBtn(false);
            }
        }
    }

    const handleMaxButtonClick = () => {
        setUnStakeAmount(helpers.formatNumberDownRound(stakingWalletInfo["stakedAmount"], 4));
        setEnableUnStakeBtn(true);
        setIsMaxAmount(true);
    }


    const submitDone = () => {
        setUnStakeAmount('0');
        setIsMaxAmount(true);
        setUnStakeCurrentStep(1);
        setIsSubmitOK(false);
    }


    function setUnStakeStepActive() {
        $('#unStake .bs-stepper-header .step').removeClass('active');
        $('#unStake .bs-stepper-header .line').removeClass('active');
        for (var i = 1; i <= 5; i++) {
            if (i <= unStakeCurrentStep) {
                $('#unStake #unStakeButtonStep' + i).addClass('active');
                $('#unStake #unStakeLineStep' + (i - 1)).addClass('active');
            }
        }
        $('#unStake .bs-stepper-content').hide();
        $('#unStake #UnStakeStep' + unStakeCurrentStep).show();
    }

    return (

        <div className="tab-pane fade" id="unStake" role="tabpanel" aria-labelledby="unStake-tab">
            <div className="mt-3 mb-4 d-flex justify-content-center align-items-center">
                <h3 className="p-tab-title mb-0">{t('Unstake your KCCPAD')}</h3>
            </div>
            <div className="row justify-content-center mb-4">
                <div className="col-lg-12">
                    <div className="bs-stepper w-100">
                        <div className="bs-stepper-header" role="tablist">
                            {/* your steps here */}
                            <div className="step active" id="unStakeButtonStep1">
                                <button type="button" className="step-trigger">
                                    <span className="bs-stepper-circle"><i className="mdi mdi-exclamation" /></span>
                                    <span className="bs-stepper-label">{t('Warning')}</span>
                                </button>
                            </div>
                            <div className="line" id="unStakeLineStep1" />
                            <div className="step" id="unStakeButtonStep2">
                                <button type="button" className="step-trigger">
                                    <span className="bs-stepper-circle"><i className="mdi mdi-format-list-checkbox" /></span>
                                    <span className="bs-stepper-label">{t('Checkpoints')}</span>
                                </button>
                            </div>
                            <div className="line" id="unStakeLineStep2" />
                            <div className="step" id="unStakeButtonStep3">
                                <button type="button" className="step-trigger">
                                    <span className="bs-stepper-circle"><i className="mdi mdi-currency-usd" /></span>
                                    <span className="bs-stepper-label">{t('Amount to Unstake')}</span>
                                </button>
                            </div>
                            <div className="line" id="unStakeLineStep3" />
                            <div className="step" id="unStakeButtonStep4">
                                <button type="button" className="step-trigger">
                                    <span className="bs-stepper-circle"><i className="mdi mdi-currency-usd" /></span>
                                    <span className="bs-stepper-label">{t('Initialize Unstake')}</span>
                                </button>
                            </div>
                            <div className="line" id="unStakeLineStep4" />
                            <div className="step" id="unStakeButtonStep5">
                                <button type="button" className="step-trigger">
                                    <span className="bs-stepper-circle"><i className="mdi mdi-check" /></span>
                                    <span className="bs-stepper-label">{t('Confirmation')}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <div className="p-sidebar-card p-sidebar-card-steps">
                        <div className="p-sidebar-card-body">
                            <div className="bs-stepper-content" id="UnStakeStep1">
                                <div className="warning" style={{flexDirection:'column'}}> 
                                    <div>
                                        <p><i className="fas fa-exclamation-triangle"></i>{t('You may be subject to a fee if you wish to unstake your KCCPAD tokens early')}</p>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-4 col-md-6">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td>{t('Staked less than 7 days')}</td>
                                                            <td className="text-end">
                                                                <h5 className="mb-0">25.00%</h5>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t('Less than 14 days')}</td>
                                                            <td className="text-end">
                                                                <h5 className="mb-0">10.00%</h5>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t('Less than 21 days')}</td>
                                                            <td className="text-end">
                                                                <h5 className="mb-0">5.00%</h5>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t('21 days or more')}</td>
                                                            <td className="text-end">
                                                                <h5 className="mb-0">0.00%</h5>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="mb-2">{t('You have staked for')} [{helpers.convertSecondsToReadableString(stakedDuration)}]. {t('Your current fee is')}: <b className="text-white">{totalPctFee}%</b></p>
                                        <p>{t("Fees will be deducted at the Withdraw stage.")}</p>
                                        <p>{t("Please click 'Next' if you wish to proceed.")}</p>
                                    </div>
                                </div>


                                {/* <Link className="btn btn-outline-primary btn-sm btn-projects" aria-current="page" to={ROUTES.PROJECTS} >
                                    <i className="mdi mdi-fire me-1"></i>
                                    <span>Projects</span>
                                </Link> */}
                            </div>
                            <div className="bs-stepper-content" id="UnStakeStep2">
                                <div className="text-center">
                                    <h4>{t('Checkpoints')}</h4>
                                    <p>{t('The following conditions must be met to proceed')}:</p>
                                </div>
                                <div className="row mt-4 d-flex justify-content-center">
                                    <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                                        <div className={isConnectWallet ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                            <div className="p-select-card-title"><b>{t('Connected with MetaMask')}</b></div>
                                            <div className="p-select-card-description">{t('If not connected, click the "Connect Wallet" button in the top right corner')}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                                        <div className={parseFloat(stakingWalletInfo["stakedAmount"]) > 0 ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                            <div className="p-select-card-title"><b>{t('Have an active KCCPAD stake')}</b></div>
                                            <div className="p-select-card-description">{t('You currently have')} {helpers.formatNumberDownRound(stakingWalletInfo["stakedAmount"], 2)} {t('KCCPAD staked')}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                                        <div className={kcsBalance > 0 ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                            <div className="p-select-card-title"><b>{t('KCS available in wallet')}</b></div>
                                            <div className="p-select-card-description">
                                                {t('KCS is required to pay transaction fees on the Kucoin Community Chain network.')}<br />
                                                {t('KCS Balance')}: {helpers.formatNumberDownRoundWithExtractMax(kcsBalance, 4)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                                        <div className={stakingWalletInfo["withdrawTimestamp"] === 0 ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                            <div className="p-select-card-title"><b>{t('Eligible to initiate unstake')}</b></div>
                                            <div className="p-select-card-description">{t('You cannot unstake if you already have an active KCCPAD unstake/withdrawal request')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 text-center">
                                    <div className="form-check">
                                        <input className="form-check-input float-none me-1"

                                            type="checkbox" defaultValue id="flexCheckDefault"
                                            onChange={() => setAcceptTerm(!acceptTerm)} />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            {t('I have read the')}&nbsp;
                                            <Link target="_blank" to={ROUTES.TERMS_OF_USE}>{t('Terms and Conditions')} </Link>
                                            {/* <a href="#">Terms and Conditions</a> */}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="bs-stepper-content" id="UnStakeStep3">
                                <div className="text-center">
                                    <h4>{t('Please enter the amount of KCCPAD you want to unstake')}</h4>
                                    <div className="mx-auto text-start mt-5" style={{ maxWidth: '370px' }}>
                                        <div className="p-form-group mb-1">
                                            <label className="form-label p-main-text">{t('Amount')}</label>
                                            <div className="p-input-group">
                                                <input type="number" className="form-control px-0" placeholder={0.0} value={unStakeAmount} onChange={(e) => handleInputUnStake(e)} />
                                                <Button onClick={handleMaxButtonClick}>{t('MAX')}</Button>
                                            </div>
                                        </div>
                                        {/* <div className="d-flex align-items-start justify-content-between">
                                            <div className="font-14">Balance: <b className="text-warning">{(bsbPadBalance || 0).toFixed(4)}</b></div>
                                            <div />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="bs-stepper-content" id="UnStakeStep4">
                                <div className="text-center">
                                    <h4>{t('Confirm Unstaking Process')}</h4>
                                    <p>{t('In this step, you initiate the unstaking process')}</p>
                                </div>
                            </div>
                            <div className="bs-stepper-content" id="UnStakeStep5">
                                <div className="text-center">
                                    <div className="text-warning">
                                        <i className="mdi mdi-check" style={{ fontSize: '50px' }} />
                                    </div>
                                    <h4>{t('Confirmed')}</h4>
                                    <p>
                                        {t('You have initiated the unstaking process.')}<br />
                                        {t('If desired, you may check Kucoin Community Chain to confirm the transaction.')}
                                    </p>
                                    <p><a className="p-address" href={`${BSC_EXPLORER}/tx/${transactionHash}`} target="blank">{transactionHash}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-3 mt-4 text-center step-buttons">
                {!isSubmitOK ?
                    <>
                        <button onClick={() => unStakeBack()} type="button"
                            disabled={!enableUnStakeBtn || unStakeCurrentStep === 1}
                            className="btn btn-primary btn-round btn-back-step me-3 px-5"><i className="mdi mdi-arrow-left me-2" />{t('Previous')}</button>
                        <button onClick={() => unStakeNext()} type="button"
                            disabled={!enableUnStakeBtn || unStakeCurrentStep === 5}
                            className="btn btn-primary btn-round btn-next-step px-5">{t('Next')}<i className="mdi mdi-arrow-right ms-2" /></button></>
                    :
                    <>
                        <button onClick={() => submitDone()} type="button"
                            className="btn btn-primary btn-round  me-3 px-5">{t('Done')}</button>
                    </>
                }

            </div>
        </div>


    );
});

export default UnStakingTab;
