import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
const CalendarItemModal = (props) => {
    const { t } = useTranslation();
    const item = props?.data;
    return (
        <>
            {item ?
                <div className="modal fade in" id="calendarItemModal" tabIndex="-1" aria-labelledby="calendarItemModalLabel" >
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content modal-content modal-help" style={{ borderRadius: '20px' }}>
                            <div className="modal-header" style={{ borderRadius: '20px 20px 0 0' }}>
                                <h4 className="modal-title d-flex align-items-center" id="calendarItemModalLabel">
                                    {item?.name}
                                </h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body p-0" style={{ borderRadius: '0 0 20px 20px' }}>

                                <div className="calendar-item mb-0" style={{ borderRadius: 0, boxShadow: 'none' }}>
                                    <div className="calendar-item-ido mb-3">{t('IDO Launch')}: <div className="d-block d-md-none"></div><b className="text-warning">
                                        {moment(item.start).utc().format('YYYY-MM-DD')}                                        
                                        </b></div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-4">
                                            <div className="calendar-item-icon" style={{ overflow: 'hidden', height: 'auto' }}>
                                                <img src={item?.icon} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-8">
                                            <div className="calendar-item-times mt-md-0 mt-3">
                                                <div className="calendar-item-time calendar-item-staking">
                                                    <span><i className="mdi mdi-calendar-outline me-1"></i>{t('Staking Eligibility Deadline')}:</span> <b>
                                                        {/* {moment.utc(item.staking * 1000).format("dddd MMMM Do, hh:mm A").toString()}  */}
                                                        {item.staking}</b>
                                                </div>
                                                <div className="calendar-item-time calendar-item-allocation">
                                                    <span><i className="mdi mdi-calendar-outline me-1 text text-capitalize"></i>{t('Allocation round')}:</span> <b>
                                                        {/* {moment.utc(item.allocation * 1000).format("dddd MMMM Do, hh:mm A").toString()}  */}
                                                        {item.allocation}</b>
                                                </div>
                                                <div className="calendar-item-time calendar-item-fcfs">
                                                    <span><i className="mdi mdi-calendar-outline me-1 text-capitalize"></i>{t('FCFS Round')}:</span> <b>
                                                        {/* {moment.utc(item.fcfs * 1000).format("dddd MMMM Do, hh:mm A").toString()}  */}
                                                        {item.fcfs}</b>
                                                </div>
                                                {/* {item.pancake &&
                                                    <div className="calendar-item-time calendar-item-launch-date">
                                                        <span><i className="mdi mdi-calendar-outline me-1"></i>PancakeSwap Launch:</span> <a href={item.pancake} target="blank"><b>{item.pancake}</b> </a>
                                                    </div>
                                                } */}

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <></>
            }


        </>
    );
}

export default CalendarItemModal;


