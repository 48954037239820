import React from 'react';
import { useTranslation } from 'react-i18next';

export default function LegalPartner({ legalPartner}) {
    const { t } = useTranslation();

    return (
        <div className="mt-5">
            <div className="col-lg-12">
                <div className="section-head">
                    <h2 className="title title-xl text-center" data-aos="fade-up" data-aos-delay="100" title="PARTNER" style={{ textTransform: 'uppercase' }}>{t('Legal Partner')}</h2>
                </div>
            </div>
            <div className="text-center mb-5">
                <div className="row justify-content-center mt-4">
                    <div className="pb-5 col-lg-12">
                        <div className="pp-card-legal-partner">
                            <img className="mx-auto" src={`${legalPartner.image}`} alt="Silk Legal" width="300" />
                            <div className="mt-4">{t(legalPartner.description)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}