import React from 'react';
import { useTranslation } from 'react-i18next';
import AdvisorCard from '../AdvisorCard';
import LegalPartner from '../LegalPartner'


export default function AdvisorsContainer({ advisors, legalPartner }) {
    const { t } = useTranslation();
    return (
        <>
            <div className="pp-home-advisors-content pp-section-body">
                <div className="container">
                    {/* <div className="row justify-content-center">
                        <AdvisorCard advisor={advisors["LESTER_LIM"]}/>
                        <AdvisorCard advisor={advisors["IAN_FRIEND"]}/>
                        
                        <AdvisorCard advisor={advisors["DANISH_CHAUDHRY"]}/>
                        <AdvisorCard advisor={advisors["EXNETWORK_CAPITAL"]}/>
                        <AdvisorCard advisor={advisors["TIM_FROST"]}/>
                    </div> */}

                    <div className="text-center mb-5">
                        <div className="section-head">
                            <h2 className="title title-xl text-center" data-aos="fade-up" data-aos-delay="100" title="PARTNER" style={{ textTransform: 'uppercase' }}>{t('INCUBATOR AND INVESTMENT PARTNER')}</h2>
                        </div>
                        <div className="row justify-content-center mt-4">
                            <div className="pb-5 col-lg-12">
                                <div className="pp-card-legal-partner">
                                    <img src='/images/bluzilla_logo.png' alt="Silk Legal" width="300" />
                                    <div className="mt-4">
                                        <p>{t('We specialize in taking your innovative and new idea from concept to completion through our market leading advisory, investment, development, influencer marketing and legal support services.')}</p>
                                        <p>{t('We are the only VC with in house developers, designers, marketers, influencers, traders, legal and launch pads.')}</p>
                                        <p>{t('We help by not only bringing capital and partners, but every aspect of your project from tokenomics to post launch marking.')}</p>
                                        <div className="pt-3">
                                            <a href="https://bluezilla.vc/" target="_blank" className="btn btn-outline-primary">Learn more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <LegalPartner legalPartner={legalPartner} />
                </div>
            </div>

        </>
    )
}