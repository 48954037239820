import { BSC_NODE_URL } from "../_configs";
import Web3 from "web3";
import abiContract from "../contracts/bscpad.json";
import { STAKING_CONTRACT_ADDRESS } from "../_configs";
import stakingABIContract from "../contracts/staking.json";
import { BigNumber } from "bignumber.js";
import axios from "axios";
import _ from "lodash";
let bscURI = BSC_NODE_URL[_.random(0, BSC_NODE_URL.length - 1)];
let web3 
export const getInfoContract = async (addresses) => {
  const info = {};
  // console.log("BSC_NODE_URL===>", BSC_NODE_URL)
  
  try {
    console.log("provider URI==>", bscURI);
    const check = await axios.get(bscURI);
    if(check.status ===200){
      web3 = new Web3(bscURI);
    }else{
      bscURI = BSC_NODE_URL[_.random(0, BSC_NODE_URL.length - 1)]
      web3 = new Web3(bscURI);
    }
  } catch (error) {
    bscURI = BSC_NODE_URL[_.random(0, BSC_NODE_URL.length - 1)]
    web3 = new Web3(bscURI);
  }
  
  //call project info
  await Promise.all(
    addresses
      .filter((address) => address !== "TBA")
      .map(async (address) => {
        // console.log(address)
        if (address === "TBA") {
          info[`${address}`] = {
            state: "P",
            symbol: "TBA",
            rate: 0,
            totalCountWallet: 0,
            totalCountUserParticipated: 0,
            totalFundParticipated: 0,
            maxSingleParticipationAllocated: 0,
            maxTotalParticipationAllocated: 0,
          };
        } else {
          const tokenContract = new web3.eth.Contract(abiContract, address);
          // console.log('call contract ',address)
          // debugger
          const contractInfo = await tokenContract.methods.info().call();

          const contractInfoRound = await tokenContract.methods
            .infoRounds()
            .call();

          // console.log(contractInfoRound);
          const tokenAddress = contractInfo[0];
          const symbol = contractInfo[1];
          const decimal = parseInt(contractInfo[2]);
          const rate = parseFloat(contractInfo[3] / 10 ** 6);
          const openTime = parseInt(contractInfo[4]);
          const fcfsOpenTime = parseInt(contractInfo[5]);
          const closeTime = parseInt(contractInfo[6]);
          // const isPrivate = contractInfo[6]
          const totalCountWallet = contractInfo[7];
          const state = contractInfo[8];
          const totalCountUserParticipated = contractInfo[9];
          const totalFundParticipated = parseFloat(
            contractInfo[10] / 10 ** decimal
          );
          // const maxSingleParticipationAllocated = parseFloat(contractInfo[11] / 10 ** decimal)
          // const maxTotalParticipationAllocated = parseFloat(contractInfo[11] / 10 ** decimal)
          const maxTotalParticipationAllocated = parseInt(
            contractInfo[11].substring(0, contractInfo[11].length - decimal)
          );

          let infoRound = [];
          for (let i = 0; i < contractInfoRound["0"].length; i++) {
            infoRound.push({
              round: contractInfoRound[0][i],
              opens: contractInfoRound[1][i],
              closes: contractInfoRound[2][i],
            });
          }
          // const now = new Date().getTime() /10000 + 10000;

          info[`${address}`] = {
            tokenAddress,
            symbol,
            decimal,
            rate,
            openTime,
            fcfsOpenTime,
            closeTime,
            totalCountWallet,
            totalCountUserParticipated,
            totalFundParticipated,
            maxTotalParticipationAllocated,
            state,
            infoRound,
          };
        }
      })
  );
  // console.log(info)
  return info;
};

//get info from staking contract

export async function getStakingContractInfo() {

  // console.log("BSC_NODE_URL===>", BSC_NODE_URL)
  const bscURI = BSC_NODE_URL[_.random(0, BSC_NODE_URL.length - 1)];
  // console.log("provider URI==>", bscURI);
  const web3 = new Web3(bscURI);
  const stakingContract = new web3.eth.Contract(
    stakingABIContract,
    STAKING_CONTRACT_ADDRESS
  );

  try {
    const info = await stakingContract.methods.info().call();
    return {
      tokenAddr: info[0],
      tokenSymbol: info[1],
      tokenDecimals: Number(info[2]),
      stakerCount: Number(info[3]),
      totalStakedAmount: BigNumber(info[4])
        .dividedBy(10 ** 18)
        .toString(),
      apyPercentage: Number(info[5]),
      unstakePeriod: Number(info[6]),
      isStakingPaused: info[7],
      totalRewardsDistributed: BigNumber(info[8])
        .dividedBy(10 ** 18)
        .toString(),
    };
  } catch (error) {
    // debugger
    console.log(error);
    return {
      tokenAddr: 0,
      tokenSymbol: 0,
      tokenDecimals: 0,
      stakerCount: 0,
      totalStakedAmount: 0,
      apyPercentage: 0,
      unstakePeriod: 0,
      isStakingPaused: 0,
      totalRewardsDistributed: 0,
    };
  }
}

export async function getBlockNumber() {
  const bscURI = BSC_NODE_URL[_.random(0, BSC_NODE_URL.length - 1)];
  const web3 = new Web3(bscURI);
  return web3.eth.getBlockNumber();
}