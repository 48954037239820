export const MESSAGES = {


  NORMAL_ERROR: "Oop! Have an error",
  // NUMBER_EXPIRED: 'Number verify expired',


  APPROVE_TOKEN_SUCCESS: 'Approve Success',
  APPROVE_TOKEN_FAILS: 'Failed to Approve Tokens',

  BUY_TOKEN_SUCCESS: 'Successfully Joined Pool',
  BUY_TOKEN_FAIL: 'Failed to Join Pool',


  SYNC_KYC_SUCCESS: 'Sync kyc to KCCPAD Success',
  SYNC_KYC_FAIL: 'Failed  to Sync kyc to KCCPAD ',

  WRONG_NETWORK:'Wrong network! You need connect to Kucoin Community Chain network!'


};
